import Menu from './Menu';
import SectionController from './SectionController';
class Modals {
  constructor(modals) {
    this.modals = modals;

    modals.forEach(modal => {
      this[`_${modal}`] = new Menu();
      this[modal] = new SectionController(this, `_${modal}`);
    });
  }

  closeAll = () => {
    this.modals.forEach(modal => this[`_${modal}`].close());
  };
}

export default Modals;
