import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';
import tr from 'react-intl/locale-data/tr';
import de from 'react-intl/locale-data/de';
import sv from 'react-intl/locale-data/sv';
import zh from 'react-intl/locale-data/zh';
import { addLocaleData } from 'react-intl';

[en, de, es, sv, zh, tr].forEach(addLocaleData);
// removed langauges for launch [id, fr, ja, fi, pt, zh, no]

class Language {
  /**
   * Loads a language resource bundle, and matching polyfil if Intl isn't supported
   *
   * See here ...
   * https://github.com/andyearnshaw/Intl.js#intljs-and-browserifywebpack
   *
   * @param lang
   * @returns {*}
   */
  static load(lang = App.Globals.lang) {
    return new Promise((resolve, reject) => {
      import(`i18n/${App.Globals.langFolder}/${lang}.json`)
        .then(localeData => {
          localStorage.setItem('locale', JSON.stringify(localeData));
          App.Globals.LOCALE = localeData;
          resolve(localeData);
        })
        .catch(() => {
          location.href = `${App.Urls.Root}/${App.Features(
            'bundle.defaultLang',
          )}`;
        });
    });
  }

  /**
   * Removes the language code from the beginning of the path
   * @param path
   * @returns {*}
   */
  static remove(path) {
    const { lang } = App.Globals;
    path = path.replace(/^\/+|\/+$/i, '');

    // replaces the language part of the path, when succeeded with further route uris
    // 'en/some/other/route' removes 'en/
    const regEx = new RegExp(`^\/?(${lang})/`, 'i');
    path = path.replace(regEx, '');

    // if the pathname exactly matches a language (ie. home page) remove it
    // path == 'de' remove, but path == 'deposit' donot remove
    if (path === lang) path = '';
    return path;
  }

  /**
   * Replaces the locale in the provided path
   * @param path
   * @param locale
   */
  static replace(path, lang, search, validate = true) {
    // remove leading/trailing slashes
    const pathname = path.replace(/^\/+|\/+$/i, '');
    const pathParts = pathname.split('/');
    // remove first part, which is always lang
    if (pathParts.length && (!validate || this.supported(pathParts[0]))) {
      pathParts.shift();
    }
    // join remaining parts together if there are any
    const suffix = pathParts.length ? `/${pathParts.join('/')}` : '';
    // and prepend new language
    return `/${lang}${suffix}${search}`;
  }

  static supported = lang => {
    // if null or empty not supported
    if (!lang || _.isEmpty(lang)) {
      return false;
    }
    // is one of our supported langs?
    return App.Config.locales[lang];
  };
}

export default Language;
