import { colors } from 'app/theme/colors';

export default {
  selectionView: {
    height: '25px',
    borderRadius: '2',
    block: {
      border: 'none',
    },
    selected: {
      border: 'none',
      fontWeight: '700',
      borderLeft: 0,
    },
  },
};
