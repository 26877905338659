import aesthetic from 'app/theme/aesthetic';
import components from 'app/theme/components';
import fonts from 'app/theme/fonts';
import functions from 'app/theme/functions';
import dimensions from 'app/theme/dimensions';
import mixins from 'app/theme/mixins';
import globals from 'app/theme/globals';
import keyFrames from 'app/theme/keyFrames';
import fontFaces from 'app/theme/fontFaces';
import fontSizes from 'app/theme/fontSizes';
import { breakpoints } from 'app/theme/breakpoints';

const space = 20;
const unit = 'px';

aesthetic.registerTheme(
  'base',
  {
    colors: {},
    functions: {
      ...functions,
    },
    border: {
      width: '1px',
    },
    dimensions: {
      ...dimensions,
    },
    mixins: {
      ...mixins,
    },
    breakpoints,
    ...components,
    font: {
      family: { ...fonts },
      size: {
        ...fontSizes,
      },

      weight: {
        heavy: '900',
        bold: '700',
        medium: '600',
        light: '400',
        extraLight: '300',
      },
    },
    space: multiplyer =>
      multiplyer === undefined
        ? `${space}${unit}`
        : `${Math.round(space * multiplyer)}${unit}`,
    transition: 'all 0.2s ease-in-out',
    backgroundImageUrl: '/images/background.jpg',
    backgroundImageUrl404: '/images/404.jpg',
  },
  // @ block statments
  {
    '@global': {
      ...globals,
    },
    '@keyframes': {
      ...keyFrames,
    },
    '@font-face': {
      ...fontFaces,
    },
  },
);
