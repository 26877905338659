import config from 'config/configuration';
/* global ga:false */
window.dataLayer = window.dataLayer || [];
let _debug = false;

const log = message =>
  _debug && console.log(`%c${message}`, 'color: orange; font-size: 14px;');

const assert = (assertion, message) => {
  if (!assertion) {
    throw new Error(message);
  }
};

const CustomGA = {
  /**
   * initialize:
   * GA add JavaScript tracking snippet
   * @param {String} gaTrackingID - the property ID of the Google Analytics property you wish to track
   */
  initialize: (gaTrackingID, localOption = {}) => {
    _debug = localOption.debug === true;

    log('Initializing GA');

    // arg validation
    assert(
      _.isString(gaTrackingID),
      'gaTrackingID is required in initialize()',
    );

    // GA Tracking Snippet
    /* eslint-disable */
    (function(i, s, o, g, r, a, m) {
      i['GoogleAnalyticsObject'] = r;
      (i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(
      window,
      document,
      'script',
      'https://www.google-analytics.com/analytics.js',
      'ga',
    );
    /* eslint-enable */

    ga('create', gaTrackingID, 'auto');
  },

  /**
   * set:
   * GA tracker set method
   * @param {Object} fieldsObject - a field/value pair or a group of field/value pairs on the tracker
   */
  set: fieldsObject => {
    log('Set page');

    assert(
      _.isFunction(ga),
      'CustomGA is not initialized. Run CustomGA.initialize(gaTrackingID)',
    );
    assert(
      _.isObject(fieldsObject) && !_.isEmpty(fieldsObject),
      'Non-empty "fieldsObject" is required in set()',
    );

    ga('set', fieldsObject);
  },

  /**
   * pageview:
   * GA send a pageview to Google Analytics
   */
  pageview: () => {
    log('Logging pageview');

    assert(
      _.isFunction(ga),
      'CustomGA is not initialized. Run CustomGA.initialize(gaTrackingID)',
    );

    ga('send', 'pageview');
  },

  /**
   * event:
   * GA event tracking
   * @param args.category {String} required
   * @param args.action {String} required
   * @param args.label {String} optional
   * @param args.value {Int} optional
   * @param args.nonInteraction {boolean} optional
   */
  event: (args = {}) => {
    log('Custom event');

    // check for global ga function
    if (config?.tracking?.GATrackingID) {
      assert(
        _.isFunction(ga),
        'CustomGA is not initialized. Run CustomGA.initialize(gaTrackingID)',
      );
    }

    // arg validation
    assert(
      _.isString(args.category),
      '"args.category" {String} in required in event()',
    );
    assert(
      _.isString(args.action),
      '"args.action" {String} is required in event()',
    );
    args.category = args.category.trim();
    args.action = args.action.trim();
    assert(
      args.category !== '',
      '"args.category" cannot be an empty string in event()',
    );
    assert(
      args.action !== '',
      '"args.action" cannot be an empty string in event()',
    );

    const fieldObject = {
      hitType: 'event',
      eventCategory: args.category,
      eventAction: args.action,
    };
    // Optional Fields
    if (args.label) {
      assert(_.isString(args.label), 'Expected "args.label" {String}  event()');
      args.label = args.label.trim();
      assert(
        args.label !== '',
        '"args.label" cannot be an empty string in event()',
      );
      fieldObject.eventLabel = args.label;
    }
    if (args.value) {
      assert(
        _.isNumber(args.value),
        'Expected "args.value" {Number} in event()',
      );
      fieldObject.eventValue = args.value;
    }

    if (args.nonInteraction) {
      assert(
        _.isBoolean(args.nonInteraction),
        'Expected "args.nonInteraction" {boolean} in event()',
      );
      fieldObject.nonInteraction = args.nonInteraction;
    }

    if (args.transport) {
      assert(
        ['beacon', 'xhr', 'image'].includes(args.transport),
        'Expected "args.transport" to be either: "beacon", "xhr" or "image" in event()',
      );
      fieldObject.transport = args.transport;
    }

    // Send to GA
    if (config?.tracking?.GATrackingID) {
      ga('send', fieldObject);
    }
  },
  plugin: {
    /**
     * require:
     * GA requires a plugin
     * @param name {String} required e.g. 'ecommerce' or 'myplugin'
     * @param options {Object} optional e.g {path: '/log', debug: true}
     */
    require: (name, options) => {
      log(`Require "${name}" Plugin`);

      assert(
        _.isFunction(ga),
        'CustomGA is not initialized. Run CustomGA.initialize(gaTrackingID)',
      );
      // Required Fields
      assert(_.isString(name), '"name" {String} is required in require()');
      name = name.trim();
      assert(name !== '', '"name" cannot be an empty string in require()');

      // Optional Fields
      if (options) {
        assert(
          _.isObject(options),
          'Expected "options" arg to be an Object in require()',
        );
        assert(_.isEmpty(options), 'Empty "options" given to require()');

        ga('require', name, options);
      } else {
        ga('require', name);
      }
    },

    /**
     *
     * @param {*} eventCategory
     * @param {*} eventAction
     * @param {*} eventLabel
     * @param {*} eventValue
     */
    trackEvent: (
      eventCategory,
      eventAction = '',
      eventLabel = '',
      eventValue = '',
    ) => {
      if (!eventLabel) {
        ga('send', 'event', eventCategory, eventAction);
      } else if (!eventValue) {
        ga('send', 'event', eventCategory, eventAction, eventLabel);
      } else {
        ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue);
      }
    },

    /**
     * execute:
     * GA execute action for plugin
     * Takes variable number of arguments
     * @param pluginName {String} required e.g. 'ecommerce' or 'myplugin'
     * @param action {String} required e.g. 'addItem' or 'myCustomAction'
     * @param actionType {String} optional e.g. 'detail'
     * @param payload {Object} optional e.g { id: '1x5e', name : 'My product to track' }
     */
    execute: (pluginName, action, ...args) => {
      log(`Execute "${pluginName}:${action}"`);

      assert(
        _.isFunction(ga),
        'CustomGA is not initialized. Run CustomGA.initialize(gaTrackingID)',
      );
      const payload = _.last(args);
      const actionType = args.length === 2 ? _.first(args) : null;

      assert(
        _.isString(pluginName),
        'Expected "pluginName" {String} in execute()',
      );
      assert(_.isString(action), 'Expected "action" {String} in execute()');

      const command = `${pluginName}:${action}`;

      if (_.isString(actionType) && _.isObject(payload)) {
        ga(command, actionType, payload);
      } else if (_.isObject(payload)) {
        ga(command, payload);
      } else {
        ga(command);
      }
    },
  },
};

export default CustomGA;
