import { colors } from 'app/theme/colors';

export default {
  LetterSpacer: {
    bg: 'rgba(255,255,255, 0.1)',
    letter: {
      bg: 'rgba(255,255,255, 0.1)',
    },
  },
};
