import { colors } from 'app/theme/colors';

export default {
  MyBonusesView: {
    input: {
      border: colors.warning,
    },
    table: {
      border: colors.inverseBorder,
    },
    row: {
      bg: colors['grey-90'],
    },
  },
};
