class NativeStore {
  init() {
    window.dataLayer.push({
      channel: window.isNative ? 'native' : 'web',
    });
  }
}

const store = new NativeStore();
export default store;
