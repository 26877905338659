export const loadStyles = async onSuccess => {
  // load colors
  const colorLoader = require(`app/theme/colorLoader`);
  const colors = require(`app/theme/colors`);
  const skinColors = await import(
    /* webpackChunkName: "SkinColors" */ `themes/${App.Globals.skin}/app/theme/skinColors`
  );
  const cs = colorLoader(skinColors.default);
  colors.colors = cs;

  // load variables
  const variablesLoader = require(`app/theme/variablesLoader`);
  const variables = require(`app/theme/variables`);

  let skinVariables;
  let skinVariablesMobile;
  try {
    skinVariables = await import(
      /* webpackChunkName: "SkinVariables" */ `themes/${App.Globals.skin}/app/theme/skinVariables`
    );
  } catch (e) {}

  if (App.viewport.isMobile) {
    try {
      skinVariablesMobile = await import(
        /* webpackChunkName: "SkinVariablesMobile" */ `themes/${App.Globals.skin}/app/theme/skinVariables.m.js`
      );
    } catch (e) {
      console.log('skin variables', e);
    }
  }

  const vs = variablesLoader(
    skinVariables?.default,
    skinVariablesMobile?.default,
  );
  variables.variables = vs;

  require('app/styler');
  const aesthetic = require('app/theme/aesthetic');
  // must be a better way to do this, temp solution
  aesthetic.themes.sportsbook.colors = { ...cs };
  aesthetic.themes.sportsbook.variables = { ...vs };
  App.variables = aesthetic.themes.sportsbook.variables;
  onSuccess && onSuccess();
};

const FALLBACK_SKIN = 'amelco';

const skinFromCookiesIfPresent = () =>
  __THEMES__.includes(Cookies.get('skin'))
    ? Cookies.get('skin')
    : FALLBACK_SKIN;

export const getCurrentSkin = () => {
  const skinName = __SKIN_NAME__;
  if ((skinName === 'devtest' || __DEV__) && Cookies.get('skin')) {
    return skinFromCookiesIfPresent();
  }
  return __SKIN_NAME__;
};

export const getCurrentLangFolder = () => {
  if (__DEV__ && Cookies.get('skin')) {
    return `${skinFromCookiesIfPresent()}-sportsbook`;
  }
  return __I18N_FOLDER__;
};
