import { colors } from 'app/theme/colors';

export default {
  promotionWidget: {
    text: colors.white,
    tile: {
      imageText: {
        border: colors.defaultBorder,
      },
      sports: {
        border: colors.defaultBorder,
      },
    },
    header: {
      bg: colors['grey-10'],
      text: colors.white,
    },
    link: {
      text: colors.primary,
    },
    selection: {
      border: colors.defaultBorder,
      bg: colors['grey-20'],
    },
  },
};
