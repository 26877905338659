import viewportState from 'stores/ViewportState';
import 'config/plugins';
import config from 'config/configuration';
import features from 'config/features';
import globals from 'sportsbook/Globals.js';
import NativeHelpers from '../app/util/NativeHelpers';
import Radio from 'common/system/radio/Radio';
import resolver from 'app/bootstrap/DomainResolver';
import BootStrapper from 'common/system/bootstrap/BootStrapper';
import { leadingSlashes as slashes } from 'sportsbook/util/RegEx';
import { props } from 'common/decorators/react-decorators';
import Language from 'app/i18n/Language';
import 'sportsbook/controller/AffiliatesController';
import cancelable from 'common/util/Cancelable';
import CustomGA from 'sportsbook/components/tracking/CustomGa';
import trackingStore from 'app/stores/trackingStore.js';
import { snakeCase } from 'lodash';
import {
  ROUTE_NOT_FOUND,
  APP_NAVIGATE,
  TRIGGER_RESIZE,
  ROUTE_MODAL_TYPE,
} from 'app/AppConstants';
import PageTracking from 'app/components/tracking/PageTracking';
import { ROUTES_TO_TRANSLATE } from 'app/i18n/RoutesToTranslate';
import menuState from 'stores/MenuState';
import {
  setApplicationName,
  lazyImport,
  getTrackingName,
  getQueryString,
  hashCode,
  getAllUrlParams,
} from 'app/util/Utils';
import NativeStore from 'app/stores/nativeStore';
import { format, isValid } from 'app/util/date';
import querystring from 'querystring';
import { toCurrentLang } from 'app/routes/translations';
import routing from 'app/stores/routing';
import store from 'store';
import ats from 'ats';
import OtherLevelsStore from 'stores/OtherLevelsStore';

@props({
  boot: [
    'GetColors',
    'SingleSignOn',
    'DefaultBundle',
    'ParseExternalToken',
    'GetSegmentData',
  ],
  noSportBoot: ['GetColors', 'SingleSignOn', 'DefaultBundle', 'GetSegmentData'],
})
class Application {
  PamBaseName = '';
  constructor() {
    this.subs = Radio.channel('subscriptions');
    this.session = Radio.channel('session');
    this.socket = Radio.channel('socket');
    this.data = Radio.channel('data');
    this.bus = Radio.channel('bus');

    this.init();
  }

  init = async () => {
    _.bindAll(this, 'start');
    this.Urls = resolver.init(config, this);
    window.App = this;
    this.viewport = viewportState;
    this.featuresObject = await features();
    this.setBaseName();

    if (config?.tracking?.GATrackingID) {
      this.pageTracking = new PageTracking();
    }

    this.handleResize = cancelable.debounce(this.onResize, 250, this);
    window.addEventListener('resize', this.handleResize);

    this.Config = config;
    this.Features = this.getFeature;
    this.checkHash();
    this.Import = lazyImport;

    this.initTrackJS();
    ats.config.navigate = this.navigate;
    if (App.Features('tracking.trackClicks')) {
      window.addEventListener(
        'click',
        e => {
          const eventName = getTrackingName(e.target);
          if (eventName) {
            CustomGA.event({
              category: 'Internal',
              action: 'click',
              label: eventName,
            });
          }
        },
        true,
      );
    }

    this.Config.isHeadLess =
      App.Features('isHeadLess') ||
      navigator.userAgent.includes('native-amelco-sportsbook');

    if (this.Config.client.toLowerCase() === 'sportsbooktime') {
      this.Config.isSbtime = true;
    }

    this.Globals = new globals();

    await trackingStore.init();
    trackingStore.track('homepage');

    NativeHelpers.init();

    setApplicationName();

    this.prestart();
  };

  checkHash() {
    const hash = App.Features('auth.hash');
    const storedHash = store.get('ats_hash');
    if (storedHash === hash || !hash) {
      return true;
    }
    const pw = prompt('Please enter the password', '');
    if (pw && hashCode(pw) === hash) {
      store.set('ats_hash', hash);
      return true;
    }
    this.checkHash();
  }

  initTrackJS = () => {
    const application = App.Features('trackJS');
    if (__DEV__ || !application) {
      return;
    }
    const that = this;
    import('trackjs').then(({ TrackJS }) => {
      that.TrackJS = TrackJS;
      TrackJS.install({
        application,
        token: '1c947c2dfd264fdfa4acb2ee890695b3',
      });
    });
  };

  prestart() {
    BootStrapper.boot(
      this.Features('sportsDisabled') ? this.noSportBoot : this.boot,
      true,
    ).then(this.start);
  }

  async start() {
    await ats.init({
      domain: App.Urls.domain.replace('https://', ''),
      features: this.featuresObject,
    });
    require.ensure([], require => {
      require('app/containers/App');
    });
    NativeStore.init();
    OtherLevelsStore.init();
    this.Config.rtl = _.some(['fa', 'ar'], lang => this.Globals.lang === lang);
  }

  onResize() {
    App.bus.trigger(
      TRIGGER_RESIZE,
      document.documentElement.clientWidth,
      document.documentElement.clientHeight,
    );
  }

  scrollUp = (id = 'main-content') => {
    if (App.Features('siteLayout.sticky.enabled') && !viewportState.isMobile) {
      document.body.scrollTo({ top: 0 });
      return;
    }

    const comp = document.getElementById(id);
    if (comp !== null) {
      comp.scrollTop = 0;
      comp.scrollIntoView();
    }
  };

  navigate = (
    pathName = routing.homeRoute,
    query = undefined,
    state = null,
    endPoint = false,
    goHomeForModal = false,
  ) => {
    const casinoRegex = new RegExp('casino$');
    if (casinoRegex.test(pathName)) {
      menuState.additionalInfoPopupShowed = false;
    }
    if (pathName === routing.basePath) {
      pathName = routing.homeRoute;
    }
    pathName = toCurrentLang(pathName);
    if (pathName.startsWith('http')) {
      if (!endPoint) {
        window.open(pathName);
        return;
      }
      window.location.href = pathName;
      return;
    }

    if (!query) {
      query = getQueryString();
    }
    const route = this.normalize(pathName);
    const locationObject = {
      pathname: route,
      search: querystring.stringify(query),
      state,
    };

    const isModal = routing.isRoutingModal(pathName);
    const type = routing.getRoutingModalType(pathName);
    if (isModal) {
      if (!menuState.modals[type].isOpen) {
        const params = getAllUrlParams(route);
        if (Object.keys(params).length) {
          menuState.modals[type].setRoutingParam(params);
        }
        menuState.modals[type].open();
      }
      if (type === ROUTE_MODAL_TYPE.accountModal) {
        locationObject.query = query;
        menuState.setAccountModal(locationObject);
      }

      if (goHomeForModal) {
        locationObject.pathname = this.normalize(
          toCurrentLang(routing.homeRoute),
        );
      } else {
        return;
      }
    }
    if (
      type !== ROUTE_MODAL_TYPE.accountModal &&
      menuState.modals[ROUTE_MODAL_TYPE.accountModal].isOpen
    ) {
      menuState.modals[ROUTE_MODAL_TYPE.accountModal].close();
      menuState.setAccountModal({}, true);
    }
    window.appHistory.push(locationObject);

    this.scrollUp('main-content');
    App.bus.trigger(APP_NAVIGATE);
  };

  translateRoute(pathName, lang = App.Globals.lang) {
    const noLang = Language.remove(pathName);
    const dotNotation = noLang.replace(/\//, '.');
    if (_.isEmpty(dotNotation)) return `/${App.Globals.lang}`;
    const i18n = `route.${dotNotation}`;

    if (ROUTES_TO_TRANSLATE.indexOf(i18n) === -1) {
      return pathName;
    }
    return `/${App.Intl(i18n, { lang: App.Globals.lang }, 1)}`;
  }

  isActive(path, contains = false) {
    const route = this.normalize(path);
    let { pathname } = window.location;
    pathname = decodeURIComponent(pathname);
    if (contains) {
      const procedingChar = pathname.charAt(
        pathname.indexOf(route) + route.length,
      );
      return (
        (pathname.indexOf(route) !== -1 ||
          pathname.indexOf(toCurrentLang(route)) !== -1) &&
        !procedingChar.match(/[-_]/)
      );
    }
    return pathname === route || toCurrentLang(route) === pathname;
  }

  isHome() {
    const {
      location: { pathname },
    } = window;
    const path = pathname.replace(/^\/+|\/+$/i, '');
    const { lang } = App.Globals;
    const homeRoutes = ['', '/', '/home', '/home/soon'];

    return homeRoutes.some(route => path === `${lang}${route}`);
  }

  normalize = (pathName, language = true) => {
    pathName = toCurrentLang(pathName);
    pathName = Language.remove(pathName);
    if (!language) {
      return pathName;
    }
    const path = pathName.replace(slashes, '');
    const parts = [App.Globals.lang];
    if (!_.isEmpty(path)) {
      parts.push(path);
    }
    return `/${parts.join('/')}`;
  };

  get isRetail() {
    return App.Features('retail.enabled') || App.Settings.BrandId === '2';
  }

  getFeature(key, defaultValue, isMobile = null, breakpoint = null) {
    if (isMobile !== null && isMobile !== viewportState.isMobile) {
      return false;
    }
    if (
      breakpoint !== null &&
      !viewportState.isSmallerThanBreakpoint(breakpoint)
    ) {
      return false;
    }
    return _.get(this.featuresObject, key, defaultValue);
  }

  Variables(key, defaultValue) {
    if (!this.variables) {
      return defaultValue;
    }
    return _.get(this.variables, key, defaultValue);
  }

  isFeatureMatch(key, value) {
    const feature = this.getFeature(key);
    if (!feature) {
      return false;
    }
    return feature.test(value);
  }

  toTimeFormat(time) {
    if (!time) {
      return '';
    }
    const date = new Date(Number(time));
    if (!isValid(date)) {
      console.log('invalid date');
    }
    return format(date, this.langFeatures('global.dateTimeFormat'));
  }

  toDateFormat(date) {
    if (!date) {
      return '';
    }
    const ret = new Date(date);
    if (!isValid(ret)) {
      console.log('invalid date');
    }
    return format(ret, this.langFeatures('global.dateFormat'));
  }

  setBaseName = () => {
    if (
      !this.getFeature('external.enabled') ||
      this.getFeature('external.iframe')
    ) {
      return;
    }
    const pathname = window.location.pathname;
    if (pathname) {
      const pn = pathname.split('#')[0].substring(1);
      this.PamBaseName = pn;
    } else {
      this.PamBaseName = App.Settings.PamBaseName;
    }
  };

  langFeatures = key => {
    const lang = this.Globals.lang;

    const feature = App.Features(key);
    if (!feature) {
      return '';
    }
    return feature[snakeCase(lang)] || feature.default || feature;
  };

  currencyFeatures = (key, currency, def = '') => {
    const feature = App.Features(key);
    if (!feature) {
      return def;
    }
    return feature[currency] || feature.default || feature;
  };
}

const inst = new Application();
export default inst;
