import { colors } from 'app/theme/colors';

export default {
  pagination: {
    link: {
      hover: {
        text: colors.primary,
      },
      notDisabled: {
        text: colors.white,
      },
      disabledHover: {
        text: colors['grey-70'],
      },
    },
  },
};
