// matches leading and trailing slashes in a url
export var leadingTrailingSlashes = /^\/+|\/+$/g;

export var leadingSlashes = /^\//;

export var trailingSlashes = /\/$/;

// matches the protocol segment in a url
export var urlPrototcol = /(?:(?:https?|ftp|wss?):\/\/)/g;

// matches ip addresses in urls
export var ipAddress = /(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}):(\d{1,5})/;

// matches the port number in urls
export var portNumber = /:\d+/g;

// Matches any digit(s) (i.e. 0-9)
export var digits = /^\d+$/;

// Matches any alpha(s)  within a string i.e is a char present
export var alphas = () => {
  return App.Features('regEx.alphas');
};

// Matches any diditss within a string i.e is a number present
export var hasDigits = /[0-9]/;

// Matches only alpha characters
export var alpha = /^[a-z]$/i;

// Matches only alphabets upper and lower case
export var string = /^[a-z]*$/i;

// Matches only alphabets upper and lower case with spaces
export var alphabetsWithSpaces = () => {
  return App.Features('regEx.alphabetsWithSpaces');
};

// Matches phone number
export var phone = /^[0-9-\\/]*$/;

// To check a mobile phone containing at least 8 digits and can accept a + in first char(this will make 9 chars acceptable)
export var mobilePhone = /^(\+)?\d{8,}$/;

// To check a mobile phone containing at least 10 digits and can accept a + in first char(this will make 11 chars acceptable)
export var mobilePhoneBaja = /^(\+)?\d{10,}$/;

export var numericAndPlus = /^[0-9+]+$/;

// explicit check for 8 digits in the string
export var hasEightDigitCheck = /^\D*(?:\d\D*){8,}$/;

// explicit check for 9 digits in the string
export var hasNineDigitCheck = /^\D*(?:\d\D*){9,}$/;

// Matches Alpha, numbers and space, no special characters
export var alphaNumeric = () => {
  return App.Features('regEx.alphaNumeric');
};

// Username, alpha numeric with space without leading and trailing spaces
export var alphaNumeric2 = () => {
  return App.Features('regEx.alphaNumeric2');
};

// street, alpha numeric with space without leading and trailing spaces. Comma, dot, hyphen and single quote accepted
export var alphaNumeric3 = () => {
  return App.Features('regEx.alphaNumeric3');
};

// Username, alpha numeric with space and '-' without leading and trailing spaces
export var alphaNumeric4 = () => {
  return App.Features('regEx.alphaNumeric4');
};

// address, alpha numeric with space without leading and trailing spaces. Comma, dot, hyphen, single quote,colon,hash,amp,slash and braces accepted
export var alphaNumeric5 = () => {
  return App.Features('regEx.alphaNumeric5');
};

// Username, alpha numeric with space and international characters
export var username = (emailAllow = true) => {
  if (emailAllow) {
    return App.Features('regEx.usernameEmail');
  }
  return App.Features('regEx.username');
};

// Password - supports alpha, numeric, special characters, min 6 max 20
export var password = /^[A-Za-z0-9!@#$%^&*()_]$/;

// To check a password between 8 to 20 characters which contain at least one numeric digit, and one lowercase letter
export var password2 = () => {
  return App.Features('regEx.password2');
};

// Matches any number (e.g. 100.000)
export var number = /^\+?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/;

// Matches a valid email address (e.g. mail@example.com)
export var email = () => {
  return App.Features('regEx.email');
};

// Matches any valid url (e.g. http://www.example.com)
export var url =
  "^(https?|ftp)://(((([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[da-f]{2})|[!$&'()*+,;=]|:)*@)?(((d|[1-9]d|1dd|2[0-4]d|25[0-5]).(d|[1-9]d|1dd|2[0-4]d|25[0-5]).(d|[1-9]d|1dd|2[0-4]d|25[0-5]).(d|[1-9]d|1dd|2[0-4]d|25[0-5]))|((([a-z]|d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))).)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))).?)(:d*)?)(/((([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[da-f]{2})|[!$&'()*+,;=]|:|@)+(/(([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[da-f]{2})|[!$&'()*+,;=]|:|@)*)*)?)?(?((([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[da-f]{2})|[!$&'()*+,;=]|:|@)|[\uE000-\uF8FF]|/|?)*)?(#((([a-z]|d|-|.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[da-f]{2})|[!$&'()*+,;=]|:|@)|/|?)*)?$";

// Matches scores from the end of selection name for markets
// such as Correct Score.  allows for optional spaces between
// digits and numbers, for example:
// Bayer 04 Leverkusen 1 - 0 = '1 - 0'
// Bayer 04 Leverkusen 1-0 = '1-0'
export var scores = /\d+\s?-\s?\d+$/;

// Matches Any character except *%?"; (For house number in Personal Details)
export var houseNumber = /^[^*%?"; ]+$/;

export var nationalId = () => {
  return App.Features('regEx.nationalId');
};
export var ssnLast4Digits = () => {
  return /^[0-9]{4}$/;
};
export var rewardCardNumber = () => {
  return /^[0-9]{6}$/;
};
export var rewardCardNumberMajestic = () => {
  return /^[0-9]{7,9}$/;
};

export var postCode = () => {
  return App.Features('regEx.postCode') || App.Features('regEx.alphaNumeric2');
};

export const dobRegEx = new RegExp(
  /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
);
