import { colors } from 'app/theme/colors';

export default {
  depositConfirmView: {
    total: {
      border: colors.popBorder,
    },
    icon: {
      text: colors.primary,
    },
  },
};
