import { action, observable } from 'mobx';

const ua = navigator.userAgent;
const platform = navigator.platform;

class DeviceState {
  isIphone =
    ua.match(/ipad|ipod|iphone/i) ||
    (platform === 'MacIntel' && navigator.maxTouchPoints > 1);
  isFirefox = ua.toLowerCase().includes('firefox');
  isEdge = ua.toLowerCase().includes('edge');
  isWindows = ua.toLowerCase().includes('windows');
  isLinux = ua.toLowerCase().includes('linux');
  isMac = ua.toLowerCase().includes('macintosh');
  isAndroid = /android/i.test(ua);

  constructor() {
    window.addEventListener('touchstart', this.onFirstTouch, false);
  }

  @observable
  hover = true;

  @action
  onFirstTouch = () => {
    this.hover = false;
    window.removeEventListener('touchstart', this.onFirstTouch, false);
  };
}

const store = new DeviceState();
export default store;
