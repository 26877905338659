import deepMerge from 'deepmerge';
import { getCurrentSkin } from '../app/util/ThemeUtils';
import defaultFeatres from './defaultFeatures';

// we want to merge the destination with source but when coming accros an array replace it entirely
const dontMerge = (destination, source) => source;
const features = async (skin = process.env.SKIN) => {
  let mode = process.env.MODE;

  if (
    __FEATURES__.external.enabled &&
    !__DEV__ &&
    !__FEATURES__.external.iframe
  ) {
    const publicPath = config.domain.url + '/';
    console.log('setting webpack public path as: ' + publicPath);
    __webpack_public_path__ = publicPath;
  }

  try {
    skin = getCurrentSkin();
    mode = __MODE__;
  } catch (e) {}

  let skinFeatures = {};
  let skinFeaturesMobile = {};
  let modeFeatures = {};
  let modePriorityOverSkin = false;
  if (skin) {
    const skinName = skin.replace('-sportsbook-skin', '');
    try {
      try {
        if (mode) {
          const mf = await import(
            /* webpackChunkName: "Features mode" */ `./modes/${mode}`
          );
          if (mode === 'african_retail') {
            modePriorityOverSkin = true;
          }
          modeFeatures = mf.default;
        }
      } catch (e) {}

      const sf = await import(
        /* webpackChunkName: "Skin Features" */ `themes/${skinName}/config/skinFeatures`
      );
      skinFeatures = sf.default;
    } catch (e) {}

    if (document?.documentElement?.clientWidth < 544) {
      try {
        const sfmob = await import(
          /* webpackChunkName: "Skin Features Mobile" */ `themes/${skinName}/config/skinFeatures.m`
        );
        skinFeaturesMobile = sfmob.default;
      } catch (e) {}
    }
  }

  return deepMerge.all(
    modePriorityOverSkin
      ? [defaultFeatres, skinFeatures, skinFeaturesMobile, modeFeatures]
      : [defaultFeatres, modeFeatures, skinFeatures, skinFeaturesMobile],
    {
      arrayMerge: dontMerge,
    },
  );
};

export default features;
