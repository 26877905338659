import { colors } from 'app/theme/colors';

export default {
  EmptyMarketsView: {
    container: {
      bg: colors['grey-30'],
      text: colors.white,
      borderTop: colors['grey-10'],
      borderBottom: colors['grey-20'],
    },
  },
};
