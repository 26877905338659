/* ==========================================
 xs: 0px    ->  399px -> Mobile
 xssm: 400px ->   543x  -> Phablet
 sm: 544px  ->  767px -> Md Tablet
 md: 768px  ->  990px -> Lg Tab
 lg: 991px  ->  1023px -> Sm Desktop
 lgxl: 1024px -> 1365px -> Md Desktop
 xl: 1366px   ->  2139px -> Lg Desktop
 xxl: 2140px ->   ++++++ -> Xl Desktop
 ========================================== */

// must be in accending order of the untmost importance.
const gridBreakpoints = {
  xs: 0,
  xssm: 400,
  sm: 544,
  md: 768,
  lg: 991,
  lgxl: 1024,
  xl: 1366,
  xxl: 2140,
};

const unit = 'px';

/*
 * example usage in JSS styles component
 * main: {
 *  ...breakpoint.up('lg', {
 *    backgroundColor: 'red',
 *  }).values(),
 * }
 */

class Breakpoints {
  constructor() {
    this._values = {};
  }

  // Media of at least the minimum breakpoint width. No query for the smallest breakpoint.
  // Makes the @content apply to the given breakpoint and wider.
  up = (key, styles) => {
    const value = gridBreakpoints[key];
    if (typeof value === 'number' && value !== 0) {
      this._add(`(min-width:${value}${unit})`, styles);
    } else {
      console.warn('invalid breakpoint');
    }

    return this;
  };

  // Media of at most the maximum breakpoint width. No query for the largest breakpoint.
  // Makes the @content apply to the given breakpoint and narrower.
  down = (key, styles) => {
    const value = this._max(key);
    if (typeof value === 'number' && !!value) {
      this._add(`(max-width:${value}${unit})`, styles);
    } else {
      console.warn('invalid breakpoint');
    }

    return this;
  };

  // Media that spans multiple breakpoint widths.
  // Makes the @content apply between the min and max breakpoints
  between = (lower, upper, styles) => {
    let query = '';
    const maxValue = this._max(upper);
    const minValue = gridBreakpoints[lower];

    if (minValue !== 0) {
      query += `(min-width:${minValue}${unit}) `;
    }
    if (!!maxValue && minValue !== 0) {
      query += 'and ';
    }
    if (maxValue) {
      query += `(max-width:${maxValue}${unit}) `;
    }

    if (!!maxValue || minValue !== 0) {
      this._add([`${query}`], styles);
    } else {
      console.warn('invalid breakpoint');
    }

    return this;
  };

  custom = (key, styles) => {
    if (key && styles) {
      this._add([`${key}`], styles);
    } else {
      console.warn('invalid breakpoint');
    }

    return this;
  };

  // Media between the breakpoint's minimum and maximum widths.
  // No minimum for the smallest breakpoint, and no maximum for the largest one.
  // Makes the @content apply only to the given breakpoint, not viewports any wider or narrower.
  only = (key, styles) => {
    const query = '';
    const maxValue = this._max(key);
    const minValue = gridBreakpoints[key];
    if (maxValue) {
      query.concat(`(max-width:${maxValue}${unit}) `);
    }
    if (!!maxValue && minValue !== 0) {
      query.concat('and ');
    }
    if (minValue !== 0) {
      query.concat(`(min-width:${minValue}${unit})`);
    }

    if (!!maxValue || minValue !== 0) {
      this._add([`${query}`], styles);
    } else {
      console.warn('invalid breakpoint');
    }

    return this;
  };

  mobile = styles => {
    this._add('@(min-width: 0) and (max-width: 340px)', styles);
  };

  _add = (key, val) => {
    if (!this._values[key]) {
      this._values[key] = val;
    } else {
      throw new Error(`Duplicate breakpoint "${key}", value already exists!`);
    }
  };

  // Maximum breakpoint width. False for the largest (last) breakpoint.
  // The maximum value is calculated as the minimum of the next one less 0.1.
  //
  //    >> breakpoint-max(sm, (xs: 0, sm: 544px, md: 768px))
  //    767px
  _max = key => {
    const keys = Object.keys(gridBreakpoints);
    const nextIndex = keys.indexOf(key) + 1;
    const nextItem = keys[nextIndex];
    if (nextItem) {
      return gridBreakpoints[nextItem] - 1;
    }
    return false;
  };

  values = () => ({
    '@media': this._values,
  });
}

export const breakpoints = () => new Breakpoints();
