import { colors } from 'app/theme/colors';

export default {
  headerMinimalView: {
    logo: {
      url: '/images/logos/logo-ice.png',
      bgSize: '120px',
      height: '40px',
      width: '120px',
      left: 'calc(50% - 50px / 2)',
      justify: 'center',
    },
  },
};
