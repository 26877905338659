import { colors } from 'app/theme/colors';

export default {
  BetsView: {
    tabsHeader: colors.secondaryBg,
    tabsBorder: colors.inverseBorder,
    tableText: colors.white,
    table: {
      bg: colors.secondaryBg,
    },
  },
};
