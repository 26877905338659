import formatDistance from './_lib/formatDistance/index.js';
import formatLong from './_lib/formatLong/index.js';
import formatRelative from './_lib/formatRelative/index.js';
import localize from './_lib/localize/index.js';
import match from './_lib/match/index.js';

/**
 * @type {Locale}
 * @category Locales
 * @summary Portuguese locale (Brazil).
 * @language Portuguese
 * @iso-639-2 por
 * @author Dário Freire [@dfreire]{@link https://github.com/dfreire}
 */
var locale = {
  formatDistance: formatDistance,
  formatLong: formatLong,
  formatRelative: formatRelative,
  localize: localize,
  match: match,
  options: {
    weekStartsOn: 1 /* Monday */,
    firstWeekContainsDate: 4,
  },
};

export default locale;
