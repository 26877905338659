import { colors } from 'app/theme/colors';

export default {
  TransactionsView: {
    tabsHeader: colors['grey-50'],
    tabsBorder: colors['grey-60'],
    tableOdd: colors['grey-40'],
    tableEven: colors['grey-30'],
    tableText: colors.white,
    greyText: {
      text: colors['grey-70'],
    },
    row: {
      shadow: '#111111',
    },
    expandableArea: {
      border: colors['grey-60'],
    },
  },
};
