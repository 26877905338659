import { set, observable, action, observe, computed } from 'mobx';
import viewportState from 'stores/ViewportState';
import Menu from './Menu';
import BurgerMenu from './BurgerMenu';
import BetslipMenu from './BetslipMenu';
import BreadcrumbMenu from './BreadcrumbMenu';
import Modals from './Modals';
import features from 'config/features';
import { toEnglish } from 'app/routes/translations';
import routing from 'app/stores/routing';

class MenuState {
  // Menus with non standard state
  burgerMenu = new BurgerMenu();
  betslip = new BetslipMenu(this.burgerMenu);
  breadcrumb = new BreadcrumbMenu();

  // simple menu state
  search = new Menu();
  casinoSearch = new Menu(viewportState.isSmallerThanBreakpoint('md'));
  liveCalendarSport = new Menu();
  userMenu = new Menu(false, {}, [this.burgerMenu]);
  registerCta = new Menu();
  headerMinimalSearch = new Menu(
    _.get(features(), 'header.minimal.searchExpanded'),
  );

  // globaly used to check if one is open.
  // file controls own open state as there is many
  numericalInputKeypad = new Menu();

  // new Modals will provide your key a new Menu API and will close all other
  // "modal" menus when one is opened so we dont have issues with modals being
  // on top of one another.
  modals = new Modals([
    'register',
    'login',
    'verificationSuccess',
    'accountVerification',
    'account',
    'termsAndConditions',
    'lifetimeDeposit',
    'layoutModal',
    'selfLimits',
    'rgVideoModal',
  ]);

  @observable
  scrollY = 0;
  @observable
  langSwitcherOpen = false;
  @observable
  oddsSwitcherOpen = false;
  @observable
  userSettings = false;
  @observable
  settingsMenuOpen = false;
  @observable
  currentPath = '';
  @observable
  eachWayExplanation = false;
  @observable
  netBetAmtExplanation = false;
  @observable
  confirmDeleteBet = false;
  @observable
  betslipTabIndex = 0;
  @observable
  betSlipSection = 'default';
  @observable
  oddsChangeConfirmationOpen = false;
  @observable
  betSuccess = false;
  @observable
  selectionAddedToBetslipButton = false;
  @observable
  raceResults = false;
  @observable
  productsNavDropdownOpen = false;
  @observable
  passwordChangeOpen = false;
  @observable
  showDepositRestriction = false;
  @observable
  mobileBreadcrumbOpen = false;
  @observable
  headerShadow = false;
  @observable
  layoutToggleOpen = false;
  @observable
  mobileSearchBarDisplayed = true;
  @observable
  urlForRedirect = '';
  @observable
  quickBetslipOpen = false;
  @observable
  activitySchedulerOpen = false;
  @observable
  betslipReceiptOpen = false;
  @observable
  futureSchedulePopupOpen = false;
  @observable
  streamingPinned = false;
  @observable
  editMode = false;
  @observable
  showMybets = false;

  @observable
  locked = false;
  @observable
  kycDetailsOpen = false;
  @observable
  creditsReadyOpen = false;
  @observable
  creditsReadyAmount = 0;
  @observable
  additionalInfoPopupShowed = false;

  @observable
  accountModalLocation = {
    pathname: '',
    query: null,
    state: null,
  };

  @observable
  sportsMenuSelected = 'home';

  constructor() {
    const windowWidth = document.documentElement.clientWidth;
    if (windowWidth > 768) {
      this.headerHeight = 126;
    } else {
      this.headerHeight = 48;
    }
    this.observe();
  }

  observe = () => {
    observe(this, 'bodyLocked', () => this.bodyLock());
    observe(this, 'accountModalLocation', x => console.log(x));
    observe(this.betslip, 'isOpen', value => {
      // ife betslipcloses reset tab index
      if (!value.newValue) {
        this.betslipTabIndex = 0;
      }
    });
  };

  @computed
  get bodyLocked() {
    return Boolean(
      this.breadcrumb.lockBody ||
        this.betslip.lockBody ||
        this.burgerMenu.lockBody ||
        this.raceResults ||
        this.mobileBreadcrumbOpen ||
        this.quickBetslipOpen,
    );
  }

  checkInsideIframe = () => {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  };

  bodyLock = (lock, bypass = false) => {
    const shouldLock = lock != null ? lock : this.bodyLocked;

    // this check should not be bypassed it is for
    // sites rendering headles in an iframe ie. dbet
    if (this.checkInsideIframe() && viewportState.isMobile) {
      return;
    }

    if (viewportState.showHiddenBetslip || bypass) {
      if (shouldLock) {
        this.scrollY = window.scrollY;
        document.body.style.position = 'fixed';
        document.body.style.overflow = 'hidden';
        if (!viewportState.showHiddenBetslip) {
          document.body.style.top = `${-this.scrollY}px`;
        }
        this.locked = true;
      } else {
        document.body.style.position = 'initial';
        document.body.style.overflow = 'initial';
        window.scrollTo(0, this.scrollY);
        this.locked = false;
      }
    }
  };

  @action
  setBetSlipSection = section => (this.betSlipSection = section);

  @action
  toggleEachWayExplanation(actionType) {
    this.handleOpenCloseToggle(actionType, 'eachWayExplanation');
  }

  @action
  toggleNetBetAmtExplanation(actionType) {
    this.handleOpenCloseToggle(actionType, 'netBetAmtExplanation');
  }

  @action
  toggleOddsChangeConfimation(actionType) {
    this.handleOpenCloseToggle(actionType, 'oddsChangeConfirmationOpen');
  }

  @action
  toggleMobileBreadcrumbOpen(actionType) {
    this.handleOpenCloseToggle(actionType, 'mobileBreadcrumbOpen');
  }

  @action
  setAccountModal(locationObject, reset) {
    if (reset) {
      locationObject = {
        pathname: toEnglish(`${App.Globals.lang}/deposit/amount`),
        query: null,
        state: null,
      };
    }

    const routeValues = {
      ...locationObject,
      pathname: toEnglish(locationObject.pathname),
    };

    set(this.accountModalLocation, routeValues);
    set(routing.routeParams, routeValues.query);
  }

  @action
  betPlaced() {
    this.betSuccess = !this.betSuccess;
  }

  @action
  toggleLangSwitcher(actionType) {
    this.closeAllHeaderBut('langSwitcherOpen');
    this.handleOpenCloseToggle(actionType, 'langSwitcherOpen');
  }

  @action
  toggleOddsSwitcher(actionType) {
    this.closeAllHeaderBut('oddsSwitcherOpen');
    this.handleOpenCloseToggle(actionType, 'oddsSwitcherOpen');
  }

  @action
  toggleLayoutToggle(actionType) {
    this.closeAllHeaderBut('layoutToggleOpen');
    this.handleOpenCloseToggle(actionType, 'layoutToggleOpen');
  }

  @action
  changeLangTo(source) {
    App.bus.trigger('lang:change:request', source);
  }

  @action
  changeLayout(layout) {
    this.closeAllHeaderBut();
    this.layoutToggleOpen = false;
    App.Globals.setLayout(layout);
  }

  @action
  toggleSettingsMenu(actionType) {
    this.closeAllHeaderBut('settingsMenuOpen');
    this.handleOpenCloseToggle(actionType, 'settingsMenuOpen');
  }

  @action
  togglePasswordChange(actionType) {
    this.closeAllHeaderBut();
    this.handleOpenCloseToggle(actionType, 'passwordChangeOpen');
  }

  @action
  showHeaderShadow() {
    this.headerShadow = true;
  }

  @action
  hideHeaderShadow() {
    this.headerShadow = false;
  }

  @action
  showSelectionAddedToBetslipButton() {
    this.selectionAddedToBetslipButton = true;
  }

  @action
  hideSelectionAddedToBetslipButton() {
    this.selectionAddedToBetslipButton = false;
  }

  @action
  toggleProductsNavDropdown(actionType) {
    this.handleOpenCloseToggle(actionType, 'productsNavDropdownOpen');
  }

  @action
  forceShowDepositRestriction() {
    this.showDepositRestriction = true;
  }

  @action
  toggleRaceResults(actionType) {
    this.handleOpenCloseToggle(actionType, 'raceResults');
  }

  @action
  toggleAcivityScheduler(actionType) {
    this.handleOpenCloseToggle(actionType, 'activitySchedulerOpen');
  }

  @action
  toggleFutureSchedulePopup(actionType) {
    this.handleOpenCloseToggle(actionType, 'futureSchedulePopupOpen');
  }

  @action
  toggleBetslipReceipt(actionType) {
    this.handleOpenCloseToggle(actionType, 'betslipReceiptOpen');
  }

  @action
  toggleKycDetails(actionType) {
    this.handleOpenCloseToggle(actionType, 'kycDetailsOpen');
  }

  @action
  toggleCreditsReady(actionType, amount = 0) {
    this.creditsReadyAmount = amount;
    this.handleOpenCloseToggle(actionType, 'creditsReadyOpen');
  }

  @action
  setEditMode(editStatus) {
    this.editMode = editStatus;
  }

  @action
  toggleEditMode = () => {
    this.editMode = !this.editMode;
  };

  @action
  closeAllHeaderBut(prop) {
    const headerItems = [
      'userMenuOpen',
      'settingsMenuOpen',
      'langSwitcherOpen',
    ];
    _.each(headerItems, item => {
      if (item !== prop) {
        this.handleOpenCloseToggle('close', item);
      }
    });
  }

  @action
  openBetDeleteConfirm = bet => {
    this.confirmDeleteBet = bet;
  };

  @action
  closeBetDeleteConfirm = () => {
    this.confirmDeleteBet = false;
  };

  handleOpenCloseToggle(actionType, prop) {
    switch (actionType) {
      case 'open':
        this[prop] = true;
        break;
      case 'close':
        this[prop] = false;
        break;
      case 'toggle':
        this[prop] = !this[prop];
        break;
      default:
        break;
    }
  }

  @action
  closeAll = () => {
    this.betslipOpen = false;
    this.burgerMenu.close();
  };

  @action
  setUrlForRedirect = url => {
    this.urlForRedirect = url;
  };
}

const store = new MenuState();
export default store;
