import { createStyler } from 'aesthetic';
import aesthetic from './theme/aesthetic';
import 'app/theme/baseTheme';
import 'app/theme/theme';

// calling these sets up the theme

const { style, transform } = createStyler(aesthetic);

export const classes = transform;
export default style;
