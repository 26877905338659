// a function  that does nothing
const noop = () => null;

// example usage:
//    this.action = cancelable.debounce(this.action, 100, this);
//
//    this.action()
//
//    this.action.cancel();
//
// In the above example this.action will never be invoked
const debounce = (fn, duration, ctx) => {
  // attach `this` if provided
  if (ctx) {
    fn = fn.bind(ctx);
  }

  // basic debounce implementation:
  let timeout = null;
  const wrapped = (...args) => {
    if (!timeout) {
      timeout = setTimeout(() => {
        fn(...args);
        timeout = null;
      }, duration);
    }
  };

  // calling cancel method will stop the running timeout, thereby preventing
  // function from being invoked:
  wrapped.cancel = () => {
    clearTimeout(timeout);
  };

  return wrapped;
};

// example usage:
//    this.success = cancelable.callback(this.success, this);
//
//    myAsyncFn.then(this.success)
//
//    this.success.cancel();
//
// In the above example this.success will never be invoked
const callback = (fn, ctx) => {
  // attach `this` if provided
  if (ctx) {
    fn = fn.bind(ctx);
  }

  // wrap callback function in a function that simply invokes
  // the callback with arguments (this makes it possible to
  // replace callback function in closure):
  const wrapped = (...args) => fn(...args);

  // calling cancel method replaces the callback function
  // with a noop, when wrapped is invoked it will use the
  // noop instead of the original callback function:
  wrapped.cancel = () => {
    fn = noop;
  };

  return wrapped;
};

export default { callback, debounce };
