class DeferredQueue {
  constructor(queue, sequential = true) {
    _.bindAll(this, 'initialize', 'next', 'success', 'failure');
    this.sequential = sequential;
    this.queue = queue;
  }

  /**
   * Starts the async operation
   * @returns {Promise}
   */
  init() {
    var that = this;
    var promise = new Promise(function(resolve, reject) {
      that.resolve = resolve;
      that.reject = reject;
    });
    this.initialize();
    return promise;
  }

  /**
   * Starts the queue
   */
  initialize() {
    if (this.sequential) {
      this.next();
      return;
    }
    this.async();
  }

  /**
   * Exeecute next deferred object in the queueApp
   */
  next() {
    var that = this;
    // if there's steps left in the sequence, action them
    if (!!this.queue.length) {
      var obj = this.queue.shift();
      // Deferred Object path
      if (_.isString(obj)) {
        require.ensure([], require => {
          var Item = require(`app/bootstrap/${obj}.js`);
          obj = new Item(that.options);
          obj
            .init()
            .then(that.next)
            .catch(that.failure);
        });
      }

      // Deferred Object
      else if (_.isFunction(obj)) {
        obj = new obj(this.options);
        obj
          .init()
          .then(that.next)
          .catch(that.failure);
      }
      return;
    }
    // otherwise finish up
    this.success();
  }

  /**
   *
   */
  async() {
    var that = this;
    var all = this.queue.map(obj => {
      return new Promise((resolve, reject) => {
        // Deferred Object path
        if (_.isString(obj)) {
          require.ensure([], require => {
            var Item = require(`app/bootstrap/${obj}.js`);
            obj = new Item(that.options);
            obj
              .init()
              .then(resolve)
              .catch(reject);
          });
        }
        // Deferred Object
        else if (_.isFunction(obj)) {
          obj = new obj(this.options);
          obj
            .init()
            .then(resolve)
            .catch(reject);
        }
      });
    });

    Promise.all(all)
      .then(this.success)
      .catch(this.failure);
  }

  /**
   *
   */
  success() {
    console.log('Bootstrap: ' + this.name + ' - Success');
    this.resolve();
  }

  /**
   *
   */
  failure(e) {
    console.log('Bootstrap: ' + this.name + ' - Failure : ' + e);
    this.reject(e);
  }
}

export default DeferredQueue;
