const routes = {
  account: 'conta',
  address: 'endereco',
  amount: 'valor',
  'betslip-checker': 'verificador-de-cupons',
  bonuses: 'bonus',
  casino: 'cassino',
  communication: 'comunicacao',
  competition: 'competicao',
  competitions: 'competicoes',
  confirm: 'confirmar',
  contacts: 'contatos',
  countries: 'paises',
  country: 'pais',
  deactivate: 'desativar',
  deposit: 'depositar',
  details: 'detalhes',
  documents: 'documentos',
  error: 'erro',
  event: 'evento',
  exclusions: 'exclusoes',
  favourites: 'favoritos',
  financials: 'financas',
  home: 'casa',
  live: 'aovivo',
  login: 'entrar',
  multiview: 'multi-visualizacao',
  mybets: 'minhasapostas',
  'not-found': 'nao-encontrado',
  overview: 'visao-geral',
  password: 'senha',
  passwordexpired: 'senhaexpirada',
  passwordForgot: 'esqueceuasenha',
  passwordNew: 'novasenha',
  payout: 'pagamento',
  'player-index': 'indice-do-jogador',
  promotions: 'promocoes',
  'race-cards': 'cartoes-corrida',
  racing: 'corridas',
  refer: 'indicar',
  register: 'registro',
  registerConfirmation: 'confirmar-registro',
  restrict: 'restrito',
  results: 'resultados',
  scoreboard: 'placar',
  search: 'procurar',
  sports: 'esportes',
  streaming: 'transmissao',
  success: 'sucesso',
  today: 'hoje',
  transactions: 'transacoes',
  verificationerror: 'erro-de-verificacao',
  verify: 'verificar',
  virtual_sports: 'esportes_virtuais',
  welcome: 'bem-vindo',
  withdraw: 'retirar',
};

export default routes;
