class FacebookPixelTracking {
  constructor() {}
  track = (type = '') => {
    let url = null;
    switch (type) {
      case 'firstdepositconfirm':
        url = App.Features('tracking.facebookPT.deposit') || '';
        break;
      case 'register':
        url = App.Features('tracking.facebookPT.register') || '';
        break;
    }
    return url;
  };
}

const store = new FacebookPixelTracking();
export default store;
