import { colors } from 'app/theme/colors';

export default {
  HorseBetRow: {
    row: {
      border: colors.defaultBorder,
    },
    checkbox: {
      border: colors.defaultBorder,
      check: colors.success,
      tick: colors.white,
      disabled: colors['grey-95'],
    },
  },
};
