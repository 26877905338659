import { colors } from 'app/theme/colors';
import color from 'color';

export default {
  casinoModal: {
    bg: color(colors['grey-15'])
      .fade(0.15)
      .string(),
    text: colors.white,
    inner: {
      bg: colors['grey-10'],
      border: colors.inverseBorder,
    },
  },
};
