import { colors } from 'app/theme/colors';

export default {
  SportsMenuWidget: {
    count: {
      text: colors.highlightText,
    },
    item: {
      bg: colors.widgetBody,
    },
    selected: {
      border: colors.primary,
    },
    title: {
      text: colors.mainText,
    },
  },
};
