import ServiceBase from 'common/service/ServiceBase.js';

const Service = ServiceBase.extend({
  url() {
    return App.Urls.api;
  },
  surl() {
    return App.Urls.api;
  },
  defaults: {
    sessionToken() {
      return App.session.execute('get', 'sessionToken');
    },
    atsGeoToken() {
      return decodeURIComponent(App.session.execute('get', 'atsGeoToken'));
    },
    localToken() {
      return App.session.execute('get', 'localToken');
    },
    application() {
      return App.Config.application;
    },
    locale() {
      return `${App.Globals.locale}-x-${App.Globals.segment}`;
    },
    channelId() {
      return App.Globals.channel;
    },
  },

  targets: {
    login: {
      method: 'post',
      secure: true,
      args: [
        'username',
        'password',
        'siteId',
        'twoFaToken',
        'BrandCode',
        'deviceId',
        'blackbox',
        'deviceContext',
        'security',
        'geolocationDetails',
        { application: { attr: 'application' } },
      ],
    },

    internalLogin: {
      method: 'post',
      secure: true,
      args: [
        'username',
        'password',
        'returnUserGroupSettings',
        { application: { attr: 'application' } },
      ],
    },

    externalLogin: {
      method: 'post',
      args: [
        'externalToken',
        'webId',
        'agentId',
        { application: { attr: 'application' } },
      ],
    },

    singleSignOnLogin: {
      method: 'post',
      secure: true,
      args: [
        { returnUserGroupSettings: 'false' },
        { sessionToken: '' },
        { application: { attr: 'application' } },
      ],
    },

    logout: {
      method: 'post',
      secure: true,
      // headers: { attr: 'authorization' },
      args: [{ sessionToken: { attr: 'sessionToken' } }],
    },

    validateSession: {
      proxy: 'keepAlive',
      method: 'post',
      args: ['sessionToken'],
    },

    keepAlive: {
      method: 'post',
      secure: true,
      args: [
        'condition' && { geocomplyCheck: true },
        { sessionToken: { attr: 'sessionToken' } },
        { atsGeoToken: { attr: 'atsGeoToken' } },
      ],
    },

    changeAccountPassword: {
      args: [
        'accountId',
        'password',
        { sessionToken: { attr: 'sessionToken' } },
      ],
    },

    internalChangePassword: {
      args: [
        'username',
        'oldPassword',
        'newPassword',
        { sessionToken: { attr: 'sessionToken' } },
      ],
    },

    forgotPassword: {
      method: 'post',
      secure: true,
      args: ['username', { application: { attr: 'application' } }],
    },

    newsletterSubscribe: {
      method: 'post',
      secure: true,
      args: ['email', { application: { attr: 'application' } }],
    },

    getSportData: {
      args: [
        { sport: '' },
        { markets: 'true' },
        { keyMarkets: 'true' },
        { groups: 'true' },
        { locale: { attr: 'locale' } },
      ],
    },
  },
});

/* eslint-disable prefer-const */
let inst = new Service();
export default inst;
