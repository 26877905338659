import { colors } from 'app/theme/colors';

export default {
  buttons: {
    primary: {
      bg: colors.primary,
      text: colors.white,
    },
    secondary: {
      bg: colors['grey-70'],
      text: colors.black,
    },
  },
};
