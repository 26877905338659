import adnxs from 'stores/AdnxsPixelTracking';
import mixtraff from 'stores/MixtraffTracking';
import gsm from 'stores/GSMTracking';
import ecommerce from 'stores/EcommerceTracking';
import gaEvent from 'stores/GAEventTracking';
import facebookPT from 'stores/FacebookPixelTracking';
import segment from 'stores/Segment';
import airship from 'stores/AirshipStore';

class TrackingStore {
  init = async () => {
    if (App.Features('tracking.segment.enabled')) {
      await segment.init();
    }
    if (App.Features('tracking.airship.enabled')) {
      await airship.init();
    }
  };

  afterLogin = async (userId = undefined) => {
    if (App.Features('tracking.airship.enabled')) {
      await airship.afterLogin();
    }

    if (userId && App.Features('tracking.segment.enabled')) {
      segment.identify(userId);
    }
  };

  track = async (type = '', amount = 0, id = 0, tax = 0) => {
    if (App.Features('tracking.segment.enabled')) {
      segment.track(type);
    }
    if (App?.Globals?.isDevUat) {
      return;
    }
    if (App.Features('tracking.adnxs.enabled')) {
      adnxs.track(type, amount);
    }
    if (App.Features('tracking.mixtraff.enabled')) {
      mixtraff.track(type, amount);
    }
    if (App.Features('tracking.gsm.enabled')) {
      gsm.track(type, amount);
    }
    if (App.Features('tracking.ecommerce.enabled')) {
      ecommerce.track(type, amount, id, tax);
    }
    if (
      App.Features('tracking.gtagEvent.enabled') &&
      App.Features('tracking.gtagEvent.id') &&
      window.gtag
    ) {
      window.gtag('event', type, {
        send_to: App.Features('tracking.gtagEvent.id'),
      });
    }
    if (App.Features('tracking.facebookPT.enabled')) {
      return facebookPT.track(type);
    }
    if (App.Features('tracking.gaEvent.enabled')) {
      return gaEvent.track(type, amount);
    }
  };

  page = () => {
    if (App.Features('tracking.segment.enabled')) {
      segment.page();
    }
  };
}

const inst = new TrackingStore();
export default inst;
