import { colors } from 'app/theme/colors';

export default {
  EventMarkets: {
    markets: {
      bg: colors['grey-10'],
      text: colors.white,
      border: colors.defaultBorder,
      hover: colors.white,
    },
  },
};
