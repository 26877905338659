import { colors } from 'app/theme/colors';

export default {
  footer: {
    priceUp: {
      text: colors.primary,
    },
    priceDown: {
      text: colors.error,
    },
    successText: {
      text: colors.primary,
    },
  },
};
