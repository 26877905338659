import * as Languages from './languages';

const getKey = (part, lang, keys) => {
  return keys.find(k => part === lang[k]);
};

export const toEnglish = route => {
  const lang = App.Globals.lang;
  const currentLang = Languages[lang.replace('-', '_')];
  if (lang === 'en' || !currentLang || !route) {
    return route;
  }
  const parts = route.split('/').filter(p => !!p);
  const langKeys = Object.keys(currentLang);
  const newRoute = parts
    .map(p => getKey(p, currentLang, langKeys) || p)
    .join('/');
  return `/${newRoute}`;
};

export const toCurrentLang = (route, leadingSlash = true) => {
  const lang = App.Globals.lang;
  const currentLang = Languages[lang.replace('-', '_')];
  if (lang === 'en' || !currentLang) {
    return route;
  }
  const parts = route.split('/').filter(p => !!p);
  const newRoute = parts.map(p => currentLang[p] || p).join('/');
  return `${leadingSlash ? '/' : ''}${newRoute}`;
};

export const toLang = (route, locale) => {
  const lang = locale;
  const currentLang = Languages[lang.replace('-', '_')];
  if (lang === 'en' || !currentLang) {
    return route;
  }
  const parts = route.split('/').filter(p => !!p);
  const newRoute = parts.map(p => currentLang[p] || p).join('/');
  return `/${newRoute}`;
};
