const globals = {
  spin: {
    from: { transform: 'rotate(0deg)' },
    to: { transform: 'rotate(360deg)' },
  },
  upwardsFlash: {
    from: {
      opacity: '1',
      transform: 'translate(0, 3px)',
    },

    to: {
      opacity: '0',
      transform: 'translate(0, -3px)',
    },
  },
  downwardsFlash: {
    from: {
      opacity: '1',
      transform: 'translate(0, -3px)',
    },
    to: {
      opacity: '0',
      transform: 'translate(0, 3px)',
    },
  },
  fadeIn: {
    from: {
      opacity: '0',
    },
    to: {
      opacity: '1',
    },
  },
  loading: {
    to: {
      transform: 'rotate(360deg)',
    },
  },
  rotate270: {
    to: {
      transform: 'rotate(-90deg)',
    },
  },
  rotate0: {
    to: {
      transform: 'rotate(0deg)',
    },
  },
  draw: {
    from: {},
    to: { strokeDashoffset: '0' },
  },
  slideUp: {
    '0%': {
      transform: 'translate(0, 1000px)',
      opacity: 0,
    },
    '30%': {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
    '60%': {
      transform: 'translate(0, 0)',
      opacity: 1,
    },
    '100%': {
      transform: 'translate(0, 1000px)',
    },
  },
  pulse: {
    '0%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '25%': {
      transform: 'scale(1)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(4.5)',
      opacity: 0,
    },
  },
  shadowPulse: {
    '0%': {
      boxShadow: '0px 0px 20px',
    },
    '50%': {
      boxShadow: '0px 0px 50px',
    },
    '100%': {
      boxShadow: '0px 0px 20px',
    },
  },
  checkCircleDash: {
    to: {
      strokeDashoffset: 0,
    },
  },
  checkLineDash: {
    to: {
      strokeDashoffset: 0,
    },
  },
  changeDashOffset: {
    from: { strokeDashoffset: '150' },
    to: { strokeDashoffset: '0' },
  },
  moveChevron: {
    '25%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0.5,
      transform: `translateY(15.5rem)`,
    },
    '80%': {
      opacity: 1,
    },
    '100%': {
      opacity: 0,
      transform: `translateY(15.5rem)`,
    },
  },
  moveBubble: {
    '0%': {
      marginTop: '35px',
    },
    '10%': {
      marginTop: '45px',
    },
    '20%': {
      marginTop: '35px',
    },
    '30%': {
      marginTop: '45px',
    },
    '40%': {
      marginTop: '35px',
    },
    '50%': {
      marginTop: '45px',
    },
    '60%': {
      marginTop: '35px',
    },
  },
  shake: {
    '0%': {
      transform: 'translate(0px, 0px)',
    },
    '25%': {
      transform: 'translate(-35px, 0px)',
    },
    '50%': {
      transform: 'translate(0px, 0px)',
    },
    '75%': {
      transform: 'translate(35px, 0px)',
    },
    '100%': {
      transform: 'translate(0px, 0px)',
    },
  },
  wobble: {
    '0%': {
      transform: 'translate(0px, 0px)',
    },
    '25%': {
      transform: 'translate(-5px, 0px)',
    },
    '50%': {
      transform: 'translate(0px, 0px)',
    },
    '75%': {
      transform: 'translate(5px, 0px)',
    },
    '100%': {
      transform: 'translate(0px, 0px)',
    },
  },
  sizePulse: {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.3)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
  animatedgradient: {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
};

export default globals;
