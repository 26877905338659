import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn/index.js';
import buildLocalizeArrayFn from 'date-fns/locale/_lib/buildLocalizeArrayFn/index.js';

var weekdayValues = {
  narrow: ['do', 'se', 'te', 'qa', 'qi', 'se', 'sa'],
  short: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
  long: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
};

var monthValues = {
  short: [
    'jan',
    'fev',
    'mar',
    'abr',
    'mai',
    'jun',
    'jul',
    'ago',
    'set',
    'out',
    'nov',
    'dez',
  ],
  long: [
    'janeiro',
    'fevereiro',
    'março',
    'abril',
    'maio',
    'junho',
    'julho',
    'agosto',
    'setembro',
    'outubro',
    'novembro',
    'dezembro',
  ],
};

var timeOfDayValues = {
  uppercase: ['AM', 'PM'],
  lowercase: ['am', 'pm'],
  long: ['a.m.', 'p.m.'],
};

function ordinalNumber(dirtyNumber) {
  var number = Number(dirtyNumber);
  return number;
}

var localize = {
  ordinalNumber: ordinalNumber,
  weekday: buildLocalizeFn(weekdayValues, 'long'),
  weekdays: buildLocalizeArrayFn(weekdayValues, 'long'),
  month: buildLocalizeFn(monthValues, 'long'),
  months: buildLocalizeArrayFn(monthValues, 'long'),
  timeOfDay: buildLocalizeFn(timeOfDayValues, 'long', function(hours) {
    return hours / 12 >= 1 ? 1 : 0;
  }),
  timesOfDay: buildLocalizeArrayFn(timeOfDayValues, 'long'),
};

export default localize;
