import { colors } from 'app/theme/colors';

export default {
  StepNav: {
    step: {
      text: colors['grey-70'],
    },
    activeLink: {
      text: colors.primary,
    },
  },
};
