import { loadAsync } from 'app/util/Utils';
import routing from 'app/stores/routing.js';

const TYPES = Object.freeze({
  deposit: 'dep',
  register: 'reg',
});

class MixtraffTracking {
  track = (type = '') => {
    const hash = App.Features('tracking.mixtraff.hash');
    // eslint-disable-next-line camelcase
    const clickId = routing.utm?.click_id;
    if (!hash || !clickId) {
      return;
    }
    const sendType = TYPES[type];
    if (!sendType) {
      return;
    }
    loadAsync(
      `https://mxtads.com/postBack/post.php?hash=${hash}&type=${sendType}&data=${clickId}`,
    );
  };
}

const store = new MixtraffTracking();
export default store;
