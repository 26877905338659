import { colors } from 'app/theme/colors';

export default {
  accounts: {
    title: {
      text: colors.white,
      border: colors.primaryBorder,
    },
    desc: {
      text: colors['grey-70'],
    },
    section: {
      border: colors.inverseBorder,
    },
    label: {
      border: colors.inverseBorder,
    },
    inputs: {
      border: colors.inverseBorder,
      disabled: {
        border: colors.inverseBorder,
        text: colors['grey-75'],
      },
    },
    error: {
      text: colors.error,
    },
  },
};
