import Aesthetic from 'aesthetic';
import JSSAdapter from './lib/UnifiedAdapter';
import { create } from 'jss';
import preset from 'jss-preset-default';

const toRem = size => {
  if (!_.isUndefined(size)) {
    const sizeNum = size.toString().replace('px', '');
    if (isNaN(sizeNum)) {
      return size;
    }
    const fontSize = Number(sizeNum);
    return `${parseFloat((fontSize / parseInt(16, 10)).toPrecision(4))}rem`;
  }
};

const baseName = () => {
  const onProcessStyle = (style, rule) => {
    if (rule.type !== 'style' || __BASE_NAME__ === '/') return style;
    return _.mapObject(style, val => {
      if (val && _.isString(val) && val.indexOf('url') > -1) {
        val = val.replace('/images/', `${__BASE_NAME__}images/`);
      }
      return val;
    });
  };

  return { onProcessStyle };
};

const parseStyles = () => {
  const onProcessStyle = (style, rule) => {
    if (rule.type !== 'style') return style;
    return _.mapObject(style, (val, key) => {
      if (key === 'fontSize') {
        return toRem(val);
      }
      if (
        App.Globals.lang === 'el' &&
        key === 'textTransform' &&
        val === 'uppercase'
      ) {
        return 'capitalize';
      }
      return val;
    });
  };

  return { onProcessStyle };
};

const generateClassName = (rule, sheet) => {
  const defaultPrefix = 'c';
  let prefix = defaultPrefix;
  let jssId = '';

  if (sheet) {
    prefix = sheet.options.classNamePrefix || defaultPrefix;
    if (sheet.options.jss.id != null) jssId += sheet.options.jss.id;
  }

  /*  if (__PROD__) {
    return `${prefix}${jssId}`;
  } */

  return `${prefix + rule.key}${jssId && `-${jssId}`}`;
};

const jss = create({
  createGenerateClassName: () => generateClassName,
  plugins: [baseName(), parseStyles()].concat(preset().plugins),
});

const aesthetic = new Aesthetic(new JSSAdapter(jss), {
  defaultTheme: 'sportsbook',
  stylesPropName: 'cn',
});

export default aesthetic;
