const TYPE_MAP = {
  login_button_clicked: 'Login Clicked',
  deposit_succeeded: 'Deposit Succeeded',
  registration_succeeded: 'Registration Succeeded',
};
class Segment {
  init = async () => {
    const pkg = await import('thirdparty/segment');
    if (pkg) {
      const initialise = pkg?.default;
      initialise(
        App.Features(
          `tracking.segment.key.${App.Globals.isDevUat ? 'dev' : 'prod'}`,
        ),
      );
    }
  };

  track = async (key, parameters = undefined) => {
    if (!TYPE_MAP[key]) {
      return;
    }
    if (!window.analytics) {
      await this.init();
    }
    window.analytics.track(TYPE_MAP[key], parameters);
  };

  identify = async userId => {
    window.analytics.identify(userId);
  };

  page = () => {
    if (!window.analytics) {
      return;
    }
    window.analytics.page();
  };
}
const inst = __FEATURES__.tracking.segment.enabled && new Segment();
export default inst;
