import { colors } from 'app/theme/colors';

export default {
  OtherHorseBets: {
    container: {
      bg: colors.white,
      text: colors.mainText,
    },
    button: {
      bg: colors.white,
      border: colors['grey-75'],
    },
    betButton: {
      bg: colors['grey-70'],
      text: colors['grey-70'],
      border: colors['grey-70'],
    },
    active: {
      border: colors.highLight,
      text: colors.highlight,
      bg: colors.white,
    },
    preview: {
      text: colors.highLight,
    },
    showAll: {
      bg: colors.primaryBg,
    },
  },
};
