import { colors } from 'app/theme/colors';
import color from 'color';

export default {
  HorseRacingRecommendationView: {
    blurb: {
      text: colors.white,
    },
    highLighted: {
      text: colors.primary,
    },
    runnners: {
      border: colors.inverseBorder,
    },
    odds: {
      bg: color(colors.primary)
        .fade(0.5)
        .string(),

      text: colors.white,
    },
    topRunners: {
      text: colors.white,
    },
  },
};
