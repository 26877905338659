const fonts = {
  heading: {
    fontFamily: 'Roboto Condensed, Helvetica, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: 'bold',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '1px',
    textTransform: 'uppercase',
  },
  base: {
    fontFamily: 'Roboto, "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontSize: '12px',
    fontWeight: 'normal',
    fontStyle: 'normal',
    lineHeight: '1',
    letterSpacing: 'normal',
    textTransform: 'none',
  },
  baseCondensed: {
    fontFamily:
      'Roboto Condensed, "Helvetica Neue", Helvetica, Arial, sans-serif',
  },

  icons: 'am-icon-font',

  // bellow heer should be refactored to use non font name...
  vazir: '"Vazir", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
  'sans-serif': '"Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif',
  serif: 'Georgia, "Times New Roman", Times, serif',
};

// when langauage is RTL we wwant a different base font
if (App.Config.rtl) {
  fonts.base = fonts.vazir;
}

export default fonts;
