import $ from 'jquery';
import _ from 'underscore';
import Backbone from 'backbone';
import get from 'lodash.get';
import set from 'lodash.set';
import React from 'react';
import Intl from 'intl';

// makes touch device onclick more responsive

if (__VISUALIZE__) {
  import('react-global-render-visualizer').then(resp => {
    const visualizeRender = resp.visualizeRender;
    if (visualizeRender) {
      React.Component = visualizeRender()(React.Component);
      React.PureComponent = visualizeRender()(React.PureComponent);
    }
  });
}

if (!window.Intl) {
  // Intl polyfill
  window.Intl = Intl;
}

// jquery is required for Iforium
window.$ = $;
window.jQuery = $;

Backbone.$ = $;

window.__EMPTY__ = () => null;

// fastclick(document.body);

/**
 * Need to add a couple of underscore.string methods back
 * in, now we've taken underscore.string out
 */
_.mixin({
  includes(str, token) {
    if (token === '') return true;
    return str.indexOf(token) !== -1;
  },
  titleize(str) {
    return str.toLowerCase().replace(/(?:^|\s|-)\S/g, c => c.toUpperCase());
  },
  trim(str) {
    return str.trim ? str.trim() : str;
  },
  get,
  set,
});
