class OtherLeverlsStore {
  init = () => {
    if (!App.Features('analytics.otherLevels')) {
      return;
    }

    _ol(
      'create',
      '3d3a6d1a40c0b1923c6967bd44535eb5',
      {
        safariWebsitePushId: 'web.com.action247.sportsbook',
      },
      () => {
        console.log('OtherLevels - Session started');
      },
    );
  };

  setTrackingId = trackingID => {
    if (!App.Features('analytics.otherLevels') || !_ol) {
      return;
    }
    _ol('setTrackingId', trackingID, () => {
      console.log('OtherLevels - Tracking ID updated');
    });
  };

  registerEvent = (eventType, eventLabel) => {
    if (!App.Features('analytics.otherLevels') || !_ol) {
      return;
    }
    _ol('registerEvent', eventType, eventLabel, () => {
      console.log('OtherLevels - Event registered');
    });
  };

  setStringTag = (tag, tagValue) => {
    if (!App.Features('analytics.otherLevels') || !_ol) {
      return;
    }
    _ol('setTag', tag, tagValue, 'string', () => {
      console.log('OtherLevels - Tag set');
    });
  };

  setNumericTag = (tag, tagValue) => {
    if (!App.Features('analytics.otherLevels') || !_ol) {
      return;
    }
    _ol('setTag', tag, tagValue, 'numeric', () => {
      console.log('OtherLevels - Tag set');
    });
  };

  setTimestampTag = (tag, tagValue) => {
    if (!App.Features('analytics.otherLevels') || !_ol) {
      return;
    }
    _ol('setTag', tag, tagValue, 'timestamp', () => {
      console.log('OtherLevels - Tag set');
    });
  };

  endSession = () => {
    if (!App.Features('analytics.otherLevels') || !_ol) {
      return;
    }
    _ol('endSession', () => {
      this.init();
      console.log('OtherLevels - End session');
    });
  };
}

export default new OtherLeverlsStore();
