import { colors } from 'app/theme/colors';

export default {
  betslip: {
    bg: colors.clearBg,
    text: colors.clearSlip,
    border: colors.defaultBorder,
    borderLight: colors.inverseBorder,
    shadow: colors['grey-10'],
    summary: {
      text: colors.black,
    },
    bottom: {
      bg: colors['grey-75'],
    },
    link: {
      text: colors.white,
    },
    input: {
      bg: colors['grey-60'],
    },
    openClose: {
      bg: colors['grey-20'],
    },
    reject: {
      bg: colors['grey-20'],
      text: colors.white,
    },
    tabs: {
      bg: colors['grey-30'],
      border: colors.defaultBorder,
      hover: colors['grey-80'],
      active: {
        border: colors.primaryBorder,
      },
    },
    widgets: {
      bg: colors['grey-30'],
    },
    body: {
      bg: colors.betslipBody,
      text: colors['grey-55'],
    },
    summary: {
      bg: 'transparent',
      text: colors.white,
    },
    singleBet: {
      // Single bet within betslip
      bg: colors['off-white'],
      text: colors.betslipTeam,
      border: colors.inverseBorder,
      error: colors.error,
      odds: {
        text: colors.black,
      },
      market: {
        text: colors.betslipSelection,
      },
      liveTag: {
        bg: colors.error,
        text: colors.white,
      },
      suspended: {
        bg: colors.error,
        text: colors.white,
      },
    },
    singleBetGroup: {
      // Individual Bet Selection
      header: {
        bg: colors['off-white'],
        text: colors.black,
      },
      bets: {
        bg: colors['off-white'],
        text: colors['grey-75'],
      },
      liveTag: {
        bg: colors.error,
        text: colors.white,
      },
      priceChange: {
        up: {
          text: colors.success,
        },
        down: {
          text: colors.error,
        },
      },
    },
    sections: {
      // Sections below the betslip selections (Single, Combi, System, etc)
      header: {
        bg: colors['off-white'],
        text: colors.black,
      },
      body: {
        bg: colors['off-white'],
        text: colors.black,
      },
      input: {
        bg: colors['off-white'],
        text: colors.black,
        border: colors.inverseBorder,
      },
    },
  },
};
