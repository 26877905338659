const req = require.context('../../../scripts', true, /\.theme$/);

const components = req.keys().reduce((acc, key) => {
  _.extend(acc, req(key));
  return acc;
}, {});

const checkForErrors = (item, path = '') => {
  if (typeof item === 'object') {
    Object.keys(item).forEach(subItem => {
      if (typeof item[subItem] === 'object') {
        checkForErrors(item[subItem], path.concat(subItem, '.'));
      } else if (item[subItem] === '' || item[subItem] === undefined) {
        console.error('MISSING JSS:', path.concat(subItem));
      }
    });
  }
};

if (process.env.NODE_ENV === 'development') {
  // checkForErrors(components);
}

export default components;
