import { colors } from 'app/theme/colors';

export default {
  addressForm: {
    state: {
      text: colors['grey-75'],
    },
    error: {
      text: colors.error,
    },
  },
};
