import { colors } from 'app/theme/colors';

export default {
  CasinoCategoriesSection: {
    title: {
      text: colors.white,
      bg: colors.primary,
    },
  },
};
