import CustomGA from 'sportsbook/components/tracking/CustomGa';
import config from 'config/configuration';

class PageTracking {
  constructor() {
    try {
      CustomGA.initialize(config.tracking.GATrackingID);
      CustomGA.plugin.require('ecommerce');
    } catch (e) {
      console.warn('Google Analytics setup failure:', e.message);
    }
  }
}

export default PageTracking;
