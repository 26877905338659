import DeferredQueue from 'common/system/defer/DeferredQueue.js';

export default class BootStrapper {
  /**
   * Boot queue executes deferred objects squentially
   * @param modules
   * @returns {*}
   */
  static boot(modules, sequential = false) {
    if (!modules) return Promise.resolve();
    console.log('Bootstrap boot - start');
    var queue;
    return new Promise((resolve, reject) => {
      queue = new DeferredQueue(modules, sequential);
      queue
        .init()
        .then(resolve)
        .catch(reject);
    });
  }
}
