const dropdownStyles = (theme, top, right, selectColor) => ({
  '&::after': {
    content: '"\\e003"',
    fontFamily: theme.font.family.icons,
    position: 'absolute',
    top,
    right,
    fontSize: '11px',
  },

  '& select': {
    backgroundColor: selectColor || theme.dropdown.bg,
    color: theme.dropdown.text,
    outline: 'none !important',
    height: theme.dimensions.selectInputHeight,
    marginRight: '0',
    cursor: 'pointer',
    appearance: 'none',
  },
});

const linkColor = (color, activeColor) => ({
  color,
  cursor: 'pointer',
  '&:link': {
    color,
  },
  '&:visited': {
    color,
  },
  '&:active': {
    color: activeColor,
  },
  '&:focus': {
    color: activeColor,
  },
  '&:hover': {
    cursor: 'pointer',
    color: activeColor,
  },
});

const betslipDottedBorder = ({ colors, border }) => ({
  backgroundImage: `linear-gradient(to right, ${colors.betslipDottedBorder} 50%, ${colors.betslipItemBg} 0%)`,
  backgroundPosition: 'bottom left',
  backgroundSize: `${App.Features('betslip.isLight') ? 14 : 8}px ${
    border.width
  }`,
  backgroundRepeat: 'repeat-x',
});

const center = () => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
});

const maxWidth = () => ({
  width: '100%',
  maxWidth: '200px',
});

const size = $size => ({
  width: $size,
  height: $size,
});

const tabsBoxShadow = color => ({
  boxShadow: `1.5px 1.5px 2px -2px ${color}`,
});

const gradientBgFallback = (colors, color, type) => {
  if (type === 'primary') {
    if (App.Features('buttons.gradientBg')) {
      return colors.gradientBg;
    }
    if (App.Features('buttons.gradientBgAlt')) {
      return colors.gradientBgAlt;
    }
  }
  return color;
};

const mixins = {
  dropdownStyles,
  linkColor,
  betslipDottedBorder,
  center,
  maxWidth,
  size,
  tabsBoxShadow,
  gradientBgFallback,
};

export default mixins;
