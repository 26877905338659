const routes = {
  account: '',
  address: '',
  amount: '',
  'betslip-checker': '',
  bonuses: '',
  casino: '',
  communication: '',
  competition: '',
  competitions: '',
  confirm: '',
  contacts: '',
  countries: '',
  country: '',
  deactivate: '',
  deposit: '',
  details: '',
  documents: '',
  error: '',
  event: '',
  exclusions: '',
  favourites: '',
  financials: '',
  home: '',
  live: '',
  'live-casino': '',
  login: '',
  multiview: '',
  mybets: '',
  'not-found': '',
  overview: '',
  password: '',
  passwordexpired: '',
  passwordForgot: '',
  passwordNew: '',
  payout: '',
  'player-index': '',
  promotions: '',
  'race-cards': '',
  racing: '',
  refer: '',
  register: '',
  registerConfirmation: '',
  restrict: '',
  results: '',
  scoreboard: '',
  search: '',
  sports: '',
  streaming: '',
  success: '',
  today: '',
  transactions: '',
  verificationerror: '',
  verify: '',
  virtuals: '',
  virtual_sports: '',
  welcome: '',
  withdraw: '',
  'rush-game': '',
};

export default routes;
