const transitions = {
  dropdown: {
    '.dropdown-enter': {
      opacity: '0.01',
      transform: 'translateY(5%)',
    },
    '.dropdown-enter-active': {
      opacity: '1',
      transform: 'translateY(0%)',
      transition: 'all 150ms ease-in',
    },
    '.dropdown-exit': {
      opacity: '1',
      transform: 'translateY(0%)',
    },
    '.dropdown-exit-active': {
      opacity: '0.01',
      transform: 'translateY(15%)',
      transition: 'all 200ms ease-in',
    },
  },

  pullDown: {
    '.pullDown-enter': {
      transform: 'translateY(-100%)',
      maxHeight: '0px',
    },
    '.pullDown-enter-active': {
      transform: 'translateY(0%)',
      maxHeight: '36px',
      transition: 'all 200ms ease-out',
    },
    '.pullDown-exit': {
      transform: 'translateY(0%)',
      maxHeight: '36px',
    },
    '.pullDown-exit-active': {
      transform: 'translateY(-100%)',
      maxHeight: '0px',
      transition: 'all 200ms ease-out',
    },
  },

  fadeSlide: {
    '.fadeSlide-enter': {
      opacity: 0,
      transform: 'translateY(100%)',
    },
    '.fadeSlide-enter-active': {
      opacity: 1,
      transform: 'translateY(0%)',
      transition: 'all 350ms ease-out',
    },
    '.fadeSlide-exit': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
    '.fadeSlide-exit-active': {
      transform: 'translateY(100%)',
      opacity: 0,
      transition: 'all 350ms ease-in',
    },
  },

  fadeSlideUp: {
    '.fadeSlideUp-enter': {
      maxHeight: 0,
    },
    '.fadeSlideUp-enter-active': {
      maxHeight: '500px',
      transition: 'all 350ms',
    },
    '.fadeSlideUp-exit': {
      maxHeight: '500px',
    },
    '.fadeSlideUp-exit-active': {
      maxHeight: 0,
      transition: 'all 350ms',
    },
  },

  slideLeft: {
    '.slideLeft-enter': {
      transform: 'translateX(100%)',
    },
    '.slideLeft-enter-active': {
      transform: 'translateX(0%)',
      transition: 'all 350ms ease-out',
    },
    '.slideLeft-exit': {
      transform: 'translateX(0%)',
    },
    '.slideLeft-exit-active': {
      transform: 'translateX(-100%)',
      transition: 'all 350ms ease-in',
    },
  },

  fadeSlideLeft: {
    '.fadeSlideLeft-enter': {
      opacity: 0,
      transform: 'translateX(-100%)',
    },
    '.fadeSlideLeft-enter-active': {
      opacity: 1,
      transform: 'translateX(0%)',
      transition: 'all 350ms ease-out',
    },
    '.fadeSlideLeft-exit': {
      transform: 'translateX(0%)',
      opacity: 1,
    },
    '.fadeSlideLeft-exit-active': {
      transform: 'translateX(-100%)',
      opacity: 0,
      transition: 'all 350ms ease-in',
    },
  },

  userSettingFadeSlideUp: {
    '.userSettingFadeSlideUp-enter': {
      maxHeight: 0,
      opacity: 0,
    },
    '.userSettingFadeSlideUp-enter-active': {
      maxHeight: '500px',
      opacity: 1,
      transition: 'all 350ms',
    },
    '.userSettingFadeSlideUp-exit': {
      opacity: 1,
      maxHeight: '500px',
    },
    '.userSettingFadeSlideUp-exit-active': {
      opacity: 0,
      maxHeight: 0,
      transition: 'all 350ms',
    },
  },

  betslipSectionFadeSlide: {
    '.betslipSectionFadeSlide-enter': {
      maxHeight: 0,
      opacity: 0,
    },
    '.betslipSectionFadeSlide-enter-active': {
      maxHeight: '500px',
      opacity: 1,
      transition: 'all 350ms',
    },
    '.betslipSectionFadeSlide-exit': {
      opacity: 1,
      maxHeight: '500px',
    },
    '.betslipSectionFadeSlide-exit-active': {
      maxHeight: 0,
      opacity: 0,
      transition: 'all 350ms',
    },
  },

  favouritesFadeSlide: {
    '.favouritesFadeSlide-enter': {
      maxHeight: '0px',
      opacity: 0,
    },
    '.favouritesFadeSlide-enter-active': {
      maxHeight: '32px',
      opacity: 1,
      transition: 'all 350ms',
    },
    '.favouritesFadeSlide-exit': {
      maxHeight: '32px',
      opacity: 1,
    },
    '.favouritesFadeSlide-exit-active': {
      maxHeight: '0px',
      opacity: 0,
      transition: 'all 350ms',
    },
  },

  favouritesSectionFadeSlide: {
    '.favouritesSectionFadeSlide-enter': {
      maxHeight: 0,
      opacity: 0,
    },
    '.favouritesSectionFadeSlide-enter-active': {
      maxHeight: '100px',
      opacity: 1,
      transition: 'all 350ms',
    },
    '.favouritesSectionFadeSlide-exit': {
      maxHeight: '100px',
      opacity: 1,
    },
    '.favouritesSectionFadeSlide-exit-active': {
      maxHeight: 0,
      opacity: 0,
      transition: 'all 350ms',
    },
  },

  betslipInputKeypadFadeSlide: {
    '.betslipInputKeypadFadeSlide-enter': {
      maxHeight: 0,
      opacity: 0,
    },
    '.betslipInputKeypadFadeSlide-enter-active': {
      maxHeight: '120px',
      opacity: 1,
      transition: 'all 100ms',
    },
    '.betslipInputKeypadFadeSlide-exit': {
      maxHeight: '120px',
      opacity: 1,
    },
    '.betslipInputKeypadFadeSlide-exit-active': {
      maxHeight: 0,
      opacity: 0,
      transition: 'all 100ms',
    },
  },

  depositBonusFadeSlide: {
    '.depositBonusFadeSlide-enter': {
      maxHeight: 0,
      opacity: 0,
    },
    '.depositBonusFadeSlide-enter-active': {
      maxHeight: '400px',
      opacity: 1,
      transition: 'all 350ms',
    },
    '.depositBonusFadeSlide-exit': {
      maxHeight: '100px',
      opacity: 1,
    },
    '.depositBonusFadeSlide-exit-active': {
      maxHeight: 0,
      opacity: 0,
      transition: 'all 350ms',
    },
  },

  fade: {
    '.fade-enter': {
      opacity: 0,
    },
    '.fade-enter-active': {
      opacity: 1,
      transition: 'all 300ms',
    },
    '.fade-exit': {
      opacity: 1,
    },
    '.fade-exit-active': {
      opacity: 0,
      transition: 'all 300ms',
    },
  },

  marketGroupFade: {
    '.marketGroupFade-enter': {
      opacity: 0,
    },
    '.marketGroupFade-enter-active': {
      opacity: 1,
      transition: 'opacity 1000ms',
    },
    '.marketGroupFade-exit': {
      opacity: 1,
      maxHeight: '100px',
    },
    '.marketGroupFade-exit-active': {
      opacity: 0,
      maxHeight: 0,
      transition: 'all 1000ms',
    },
  },

  marketGroupEventFade: {
    '.marketGroupEventFade-enter': {
      background: 'hsla(0, 0%, 30%)',
    },
    '.marketGroupEventFade-enter-active': {
      background: 'transparent',
      transition: 'background 3000ms',
    },
    '.marketGroupEventFade-exit': {
      opacity: 1,
      maxHeight: '100px',
    },
    '.marketGroupEventFade-exit-active': {
      opacity: 0,
      maxHeight: 0,
      transition: 'all 1000ms',
    },
  },

  growHorizontal100: {
    '.growHorizontal100-enter': {
      maxWidth: 0,
    },
    '.growHorizontal100-enter-active': {
      maxWidth: '100px',
      transition: 'all 350ms ease-out',
    },
    '.growHorizontal100-exit': {
      maxWidth: '100px',
      // opacity: 1
    },
    '.growHorizontal100-exit-active': {
      maxWidth: 0,
      transition: 'all 350ms ease-in',
    },
  },

  growHorizontal300: {
    '.growHorizontal300-enter': {
      maxWidth: 0,
    },
    '.growHorizontal300-enter-active': {
      maxWidth: '300px',
      transition: 'all 350ms ease-out',
    },
    '.growHorizontal300-exit': {
      maxWidth: '300px',
      // opacity: 1
    },
    '.growHorizontal300-exit-active': {
      maxWidth: 0,
      transition: 'all 350ms ease-in',
    },
  },

  burgerSlide: {
    '.burgerSlide-enter': {
      left: '-100%',
    },
    '.burgerSlide-enter-active': {
      left: 0,
      transition: 'all 350ms',
    },
    '.burgerSlide-exit': {
      left: 0,
    },
    '.burgerSlide-exit-active': {
      left: '-100%',
      transition: 'all 250ms',
    },
  },

  grow: {
    '.grow-appear, .grow-enter, .grow-exit': {
      transform: 'scale(0.8)',
      transition: 'all 350ms',
    },
    '.grow-appear-active, .grow-enter-active, .grow-exit-active': {
      transform: 'scale(1.2)',
      transition: 'all 350ms',
    },
    '.grow-appear-done, .grow-enter-done, .grow-exit-done': {
      transform: 'scale(1)',
      transition: 'all 350ms',
    },
  },
};

// Squash all the above into a neat object for a neat export.
// to be applied to global JSS. Can reach the above from
// just attaching a classname to your `<CSSTransition>`.
const transitionClassNames = _.reduce(
  transitions,
  (acc, cur) => ({ ...acc, ...cur }),
  {},
);

export default transitionClassNames;
