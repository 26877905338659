import { observable } from 'mobx';
import CustomGA from 'sportsbook/components/tracking/CustomGa';
/* global ga:false */
class EcommerceTracking {
  @observable
  searchText = '';

  track = (type = '', amount = 0, id = 0, tax = 0) => {
    if (!type || !amount || !id) {
      return;
    }
    const currency = App.session.request('currency');

    switch (type) {
      case 'betconfirm':
        type = 'SportsBet';
        break;
      case 'register':
        type = App.Features('tracking.ecommerce.register') || 'register';
        break;
      case 'firstdepositconfirm':
        type =
          App.Features('tracking.ecommerce.firstdepositconfirm') ||
          'firstdepositconfirm';
        break;
      case 'deposit':
        type = App.Features('tracking.ecommerce.deposit') || 'deposit';
        break;
    }

    try {
      CustomGA.plugin.execute('ecommerce', 'addTransaction', {
        revenue: amount, // Grand Total.
        tax, // Tax.
        shipping: 0,
        id,
        affiliation: type,
        currency, // Local currency
      });
      if (type === 'SportsBet') {
        ga('set', 'dimension2', String(amount));
      } else if (type === 'deposit') {
        ga('set', 'dimension1', String(amount));
      }
      CustomGA.plugin.execute('ecommerce', 'send');
    } catch (e) {
      console.warn('Google Analytics setup failure:', e.message);
    }
  };
}

const store = new EcommerceTracking();
export default store;
