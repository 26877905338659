const routes = {
  account: 'hesap',
  address: 'adres',
  amount: 'miktar',
  'betslip-checker': 'kupon kontrol',
  bonuses: 'bonuslar',
  casino: 'casino',
  communication: 'iletişim',
  competition: 'yarışma',
  competitions: 'yarışmalar',
  confirm: 'onay',
  contacts: 'iletişim',
  countries: 'ülkeler',
  country: 'ülke',
  deactivate: '',
  deposit: 'yatırım',
  details: 'detaylar',
  documents: 'dökümanlar',
  error: 'hata',
  event: 'olay',
  exclusions: 'hariç',
  favourites: 'favoriler',
  financials: 'finansal',
  home: 'anasayfa',
  live: 'canlı',
  'live-casino': 'canlı-casino',
  login: 'giriş',
  multiview: '',
  mybets: 'bahislerim',
  'not-found': 'bulunamadı',
  overview: 'genel-bakış',
  password: 'şifre',
  passwordexpired: 'şifre-süresi-doldu',
  passwordForgot: 'şifremi-unuttum',
  passwordNew: 'yeni-şifre',
  payout: 'ödeme',
  'player-index': 'oyuncu-indeksi',
  promotions: 'promosyonlar',
  'race-cards': 'yarış-kartı',
  racing: 'yarış',
  refer: 'başvuru',
  register: 'üye-ol',
  registerConfirmation: 'üyelik-onaylandı',
  restrict: 'kısıt',
  results: 'sonuçlar',
  scoreboard: 'skor-tablosu',
  search: 'arama',
  sports: 'spor',
  streaming: 'yayın-akışı',
  success: 'başarılı',
  today: 'bugün',
  transactions: 'işlemler',
  verificationerror: 'doğrulama-hatası',
  verify: 'doğrula',
  virtuals: 'sanal',
  virtual_sports: 'sanal-sporlar',
  welcome: 'hoşgeldiniz',
  withdraw: 'çekim',
  'rush-game': 'hızlı-oyun',
};

export default routes;
