import { colors } from 'app/theme/colors';
import color from 'color';

export default {
  NumericalInput: {
    input: {
      width: '100px',
      marginLeft: '0',
      borderRadius: '2px',
    },
  },
};
