import { skinSizes, skinFontSizes } from 'app/theme/skinFontSizes';

// Temporary, need to replace with new sizes object
const oldSizes = {
  xlight: '10px',
  light: '11px',
  small: '12px',
  medium: '14px',
  large: '16px',
  extraLarge: '18px',
  giant: '24px',
  huge: '30px',
  ...skinSizes,
};

const sizes = {
  medium: '13px',
};

const fontSizes = {
  ...oldSizes,
  burgerMenuHeading: sizes.medium,
};

const mergedFontSizes = { ...fontSizes, ...skinFontSizes };

export default mergedFontSizes;
