const COOKIE_BTAG = 'btag',
  COOKIE_AFFILIATE = 'affiliateParameter';
import store from 'store';
class AffiliatesController {
  constructor() {
    this.init();
  }

  /**
   * Get the current bTag or use previous, defaults to null
   * example query string ?bTag=654557_D92E6FF69AE845C983D1BE696BE9C010&sref=ref&ref=123354
   */

  /*
   * Removes both Cookies and Local Storage for legacy
   */
  init() {
    const bTag = this.getQueryString('bTag'),
      sref = this.getQueryString('sref');

    // if user comes with new bTag replace existing
    if (bTag) {
      this.setReferer(COOKIE_BTAG, bTag);

      // if no sref remove previously stored
      if (!sref) {
        store.remove(COOKIE_AFFILIATE);
        Cookies.remove(COOKIE_AFFILIATE);
      }
    }

    // if user comes with sref store value of param, otherwise remove
    if (sref) {
      const affiliateParameter = this.getQueryString(sref);
      this.setReferer(COOKIE_AFFILIATE, affiliateParameter);

      // if no bTag remove previously stored
      if (!bTag) {
        Cookies.remove(COOKIE_BTAG);
        store.remove(COOKIE_BTAG);
      }
    }
  }

  /**
   * Set the referer cookie
   * @param  {String} name    The cookie name
   * @param  {String} value   The cookie value
   */
  setReferer(name, value) {
    store.set(name, value, { expires: 30 });
  }

  /**
   * Get the referer cookie
   * @param  {String} name    The cookie name
   * @return {String}         The cookie value
   */
  getCookie(name) {
    return store.get(name) || undefined;
  }

  /**
   * Get the value of a querystring
   * @param  {String} field The field to get the value of
   * @return {String}       The field value
   */
  getQueryString(field) {
    const href = window.location.href,
      reg = new RegExp(`[?&]${field}=([^&#]*)`, 'i'),
      string = reg.exec(href);

    return string ? string[1] : undefined;
  }
}

/* eslint-disable prefer-const */
let inst = new AffiliatesController();
export default inst;
