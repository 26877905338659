import { observable } from 'mobx';
import CustomGA from 'sportsbook/components/tracking/CustomGa';
/* global ga:false */

class GAEventTracking {
  track = (type = '', amount = 0, id = 0, tax = 0) => {
    let action = App.Features(`tracking.gaEvent.${type}`);

    if (!type || !action) {
      return;
    }

    let params = {
      eventCategory: 'Sportsbook',
      eventAction: action,
    };
    if (amount > 0) {
      params.eventLabel = 'amount';
      params.eventValue = amount;
    }

    try {
      CustomGA.plugin.trackEvent(params);
    } catch (e) {
      console.warn('Google Analytics setup failure:', e.message);
    }
  };
}

const store = new GAEventTracking();
export default store;
