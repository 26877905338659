import { observable, action, computed } from 'mobx';
import sendNativeMessage from 'app/util/SendNativeMessage';

class Globals {
  @observable
  activeEvent = null;
  @observable
  pathname = window.location.pathname;
  @observable
  href = window.location.href;
  @observable
  isActive = true;
  @observable
  latitude = '';
  @observable
  longitude = '';
  @observable
  isMultiState = false;
  @observable
  multiStateConfig = {};

  constructor() {
    if (document) {
      document.addEventListener(
        'visibilitychange',
        this.onVisibilitychange,
        false,
      );
    }
  }

  @computed
  get isLiveCasino() {
    return (
      this.pathname.indexOf('live-casino') > -1 ||
      this.pathname.indexOf('live_games') > -1
    );
  }

  @computed
  get isVirtual() {
    return this.pathname.indexOf('virtual_sports') > -1;
  }

  get maximumPayout() {
    return App.Features(`maximumPayout.${App.session.request('currency')}`);
  }

  @computed
  get locale() {
    return `${App.Globals.locale}-x-${App.Globals.segment}`;
  }

  @computed
  get multiSateValue() {
    return this.isMultiState;
  }

  @computed
  get multiStateDomain() {
    return `https://${this.multiStateConfig.value.split('=')[0]}`;
  }

  @action
  onVisibilitychange = () => {
    this.isActive = document.visibilityState !== 'hidden';
  };

  /**
   *  This function sets the multi state configuration
   *  used to switch out domains for child punter clients
   *  @param settingsValues object with category, name and value
   * */
  @action
  storeMultiStateConfig = settingsValues => {
    if (settingsValues.length) {
      this.isMultiState = true;
      const { category, name, value } = settingsValues[0];
      this.multiStateConfig = {
        category,
        name,
        value,
      };
    } else {
      this.isMultiState = false;
      console.log(
        `Unable to set multistate config as there is no settingsValue key`,
      );
    }
  };

  requestLocationPermission = () => {
    if (this.longitude && this.latitude) {
      return;
    }
    if (window.isNative) {
      sendNativeMessage('CHECK_LOCATION');
    } else {
      this.setLocation();
    }
  };

  setLocation = () => {
    if (!navigator.geolocation) {
      return;
    }
    navigator.geolocation.getCurrentPosition(
      pos => {
        this.latitude = pos.coords.latitude;
        this.longitude = pos.coords.longitude;
      },
      err => {
        console.log(`Location Error: (${err.code}): ${err.message}`);
      },
      { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 },
    );
  };
}

/* testing */
export const Class = Globals;

const store = new Globals();
export default store;
