const fontFaces = {
  Roboto: [
    {
      fontStyle: 'normal',
      fontWeight: '700',
      srcPaths: [
        '/fonts/Roboto/roboto-700.woff2',
        '/fonts/Roboto/roboto-700.woff',
      ],
    },
    {
      fontStyle: 'normal',
      fontWeight: 'Normal',
      srcPaths: [
        '/fonts/Roboto/roboto-regular.woff2',
        '/fonts/Roboto/roboto-regular.woff',
      ],
    },
    {
      fontStyle: 'normal',
      fontWeight: '300',
      srcPaths: [
        '/fonts/Roboto/roboto-300.woff2',
        '/fonts/Roboto/roboto-300.woff',
      ],
    },
  ],
  'Roboto Condensed': [
    {
      fontStyle: 'normal',
      fontWeight: '700',
      srcPaths: [
        '/fonts/Roboto_Condensed/robotocondensed-bold.woff2',
        '/fonts/Roboto_Condensed/robotocondensed-bold.woff',
      ],
    },
    {
      fontStyle: 'normal',
      fontWeight: 'Normal',
      srcPaths: [
        '/fonts/Roboto_Condensed/robotocondensed-regular.woff2',
        '/fonts/Roboto_Condensed/robotocondensed-regular.woff',
      ],
    },
    {
      fontStyle: 'normal',
      fontWeight: '300',
      srcPaths: [
        '/fonts/Roboto_Condensed/robotocondensed-light.woff2',
        '/fonts/Roboto_Condensed/robotocondensed-light.woff',
      ],
    },
  ],
};

export default fontFaces;
