import color from 'color';

const contrastText = (theme, colorToContrast) =>
  color(colorToContrast).isLight() ? '#000' : '#fff';

const darken = (hex, amount = '0.2') =>
  color(hex)
    .darken(amount)
    .string();

const lighten = (hex, amount = '0.2') =>
  color(hex)
    .lighten(amount)
    .string();

const autoContrast = (hex, amount = '0.2') =>
  color(hex).isLight()
    ? darken(color(hex).hex(), amount)
    : lighten(color(hex).hex(), amount);

/*
    Seperated this off so we can
    make this 'darken' and control value
    depending on the theme
*/
const hoverState = (hex, amount = 0.2) =>
  color(hex)
    .lighten(amount)
    .string();

const functions = {
  contrastText,
  darken,
  lighten,
  autoContrast,
  hoverState,
};

export default functions;
