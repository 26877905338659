import { observable, action } from 'mobx';

class Menu {
  @observable
  isOpen;
  // used to store linked menu states
  linked = [];

  constructor(initialValue, callbacks = {}, linked = []) {
    this.isOpen = Boolean(initialValue);
    this.callbacks = callbacks;
    this.linked = linked;
  }

  @action
  toggle = unused => {
    if (unused != null && !unused.target) {
      console.log(
        `Toggle menu no longer takes an argument. Perhaps you meant to use ${
          unused ? 'open()' : 'close()'
        }`,
      );
    }
    this.isOpen ? this.close() : this.open();
  };

  @action
  open = () => {
    this.isOpen = true;
    this.callbacks.open && this.callbacks.open();
    this.linked.forEach(menu => menu.close());
  };

  @action
  close = () => {
    this.isOpen = false;
    this.callbacks.close && this.callbacks.close();
  };
}

export default Menu;
