import { merge } from './mergeMethods.js';
import createReactClass from 'create-react-class';

/**
 * Decorates class with props
 * @param value
 * @returns {Function}
 */
export function props(value) {
  return function decorator(component) {
    _.extend(component.prototype, value);
  };
}

/**
 * Decorates a class with a Mixin
 * @param mixin
 * @returns {Function}
 * @constructor
 */
export var Mixin = function(mixin) {
  return function decorator(target) {
    merge(target, mixin);
  };
};

/**
 * Binds Globals properties to the class instance.  If for example you @BindGlobals('sport'), the
 * class' this.props will have a 'sport' property and will be updated when the sport changes.
 * @param prop
 * @returns {Function}
 * @constructor
 */
export var BindGlobal = function(prop) {
  return function decorator(Component, name, descriptor) {
    return class extends React.Component {
      constructor() {
        super();
        this.state = { [prop]: App.Globals[prop] };
        this.handleChange = this.onChange.bind(this);
      }
      componentDidMount() {
        App.bus.on(`globals:pre:${prop}Change`, this.handleChange, this);
      }
      componentWillUnmount() {
        App.bus.off(`globals:pre:${prop}Change`, this.handleChange, this);
      }
      onChange(val = App.Globals[prop]) {
        this.setState({ [prop]: val });
      }
      render() {
        const props = Object.assign({}, this.state, this.props);
        return <Component {...props} />;
      }
    };
  };
};

export var clickOutside = handler => {
  return function decorator(Component, name, descriptor) {
    const componentName = Component.displayName || Component.name;
    return createReactClass({
      displayName: `Wrapped${componentName}`,

      componentDidMount() {
        this._handler = handler || 'handleClickOutside';
        document.addEventListener('click', this.handleClickOutside, true);
      },

      componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
      },

      handleClickOutside(e) {
        const domNode = ReactDOM.findDOMNode(this);
        const isChild = domNode.contains(e.target);

        if (domNode && !isChild) {
          const func = this.refs.wrapper[this._handler];
          if (_.isFunction(func)) {
            this.refs.wrapper[this._handler](e);
          }
        }
      },

      render() {
        return <Component {...this.props} ref="wrapper" />;
      },
    });
  };
};

export var mouseOut = handler => {
  return function decorator(Component, name, descriptor) {
    const componentName = Component.displayName || Component.name;
    return createReactClass({
      displayName: `Wrapped${componentName}`,

      componentDidMount() {
        this._handler = handler || 'handleClickOutside';
        document.addEventListener('mouseover', this.handleMouseOutside, true);
      },

      componentWillUnmount() {
        document.removeEventListener(
          'mouseover',
          this.handleMouseOutside,
          true,
        );
      },

      handleMouseOutside(e) {
        const domNode = ReactDOM.findDOMNode(this);
        const isChild = domNode.contains(e.target);

        if (domNode && !isChild) {
          const func = this.refs.wrapper[this._handler];
          if (_.isFunction(func)) {
            this.refs.wrapper[this._handler](e);
          }
        }
      },

      render() {
        return <Component {...this.props} ref="wrapper" />;
      },
    });
  };
};
