/*
 Copyright(c) 2017, iovation, inc. All rights reserved.
 */
(function z() {
  function q(a) {
    var b = {},
      c;
    for (c = a.length - 1; -1 < c; c--)
      0 < c
        ? (b[c] = (function() {
            var d = c;
            return function() {
              return r(a[d], b[d + 1]);
            };
          })())
        : r(a[c], b[c + 1]);
  }
  function r(a, b) {
    var c = document.createElement("script");
    c.setAttribute("src", a);
    b && (c.onload = b);
    document.getElementsByTagName("head")[0].appendChild(c);
  }
  function d(b) {
    if ("function" === typeof a.trace_handler)
      try {
        a.trace_handler(b);
      } catch (d) {}
  }
  function e(a, b) {
    var c = null !== a && void 0 !== a;
    return !c || ("1" !== a.toString() && "true" !== a.toString().toLowerCase())
      ? !c || ("0" !== a.toString() && "false" !== a.toString().toLowerCase())
        ? "boolean" === typeof b ? b : !1
        : !1
      : !0;
  }
  var n = window,
    t = n.io_global_object_name || "IGLOO",
    a = (n[t] = n[t] || {}),
    b = (a.loader = a.loader || {}),
    v = [],
    w = [];
  if (b.loaderMain)
    return (
      d(
        "loader: Loader script has already run, try reducing the number of places it's being included."
      ),
      !1
    );
  b.loaderMain = z;
  b.loaderVer = "5.0.0";
  (function() {
    var y = e(b.tp, !0),
      h = e(b.fp_static, !0),
      c = e(b.fp_dyn, !0),
      q = e(b.enable_legacy_compatibility),
      r = e(b.tp_split),
      p = b.tp_host || "https://mpsnare.iesnare.com",
      x = b.fp_static_override_uri,
      g = void 0 !== b.uri_hook ? b.uri_hook + "/" : "/iojs/",
      u = (b.version || "versionOrAliasIsRequired") + "/",
      t = b.subkey ? n.encodeURIComponent(b.subkey) + "/" : "",
      A = b.tp_resource || "wdp.js",
      k = b.tp_host ? "&tp_host=" + n.encodeURIComponent(b.tp_host) : "",
      B = x ? "&fp_static_uri=" + n.encodeURIComponent(x) : "",
      l,
      m,
      f;
    l = e(a.enable_flash, !0);
    m = a.io && a.io.enable_flash;
    f = a.fp && a.fp.enable_flash;
    m = void 0 !== m && null !== m ? e(m, !0) : l;
    void 0 !== f && null !== f ? (f = e(f, !0)) : (m = l);
    l = m ? "&flash=true" : "&flash=false";
    f = f ? "&flash=true" : "&flash=false";
    k =
      "?loaderVer=" +
      b.loaderVer +
      "&compat=" +
      q +
      "&tp=" +
      y +
      "&tp_split=" +
      r +
      k +
      "&fp_static=" +
      h +
      "&fp_dyn=" +
      c +
      B;
    y ||
      h ||
      d("loader: Not currently configured to load fp_static or tp script(s).");
    a.fp &&
      a.fp.api &&
      a.fp.api.stver + "/" !== u &&
      ((u = a.fp.api.stver + "/"),
      d(
        "loader: Configured version replaced with that from pre-loaded static script."
      ));
    h || (a.fp && a.fp.staticMain)
      ? ((g = (g + u + t).replace(/\/\//g, "/")),
        h &&
          (a.fp && a.fp.staticMain
            ? q &&
              !a.fp.preCompatMain &&
              d(
                "loader: enable_legacy_compatibility on, but included static does not have the compat wrapper."
              )
            : x
              ? ((h = !0),
                u.match(/([0-9]+\.){2}[0-9]+/) && (h = !1),
                h
                  ? ((c = !1),
                    d(
                      "loader: You may not both override the fp static uri and used aliased versions, please either set a numeric version like #.#.# or disable fp_static_override_uri"
                    ))
                  : v.push(x))
              : v.push(g + "static_wdp.js" + k + f)),
        !c || (a.fp && a.fp.dynMain)
          ? a.fp &&
            a.fp.dynMain &&
            d(
              "loader: First party dynamic script has already been loaded, disable fp_dyn or make sure you're not manually including the dynamic file separately."
            )
          : v.push(g + "dyn_wdp.js" + k + f))
      : e(b.fp_dyn) &&
        d(
          "loader: Invalid Config, first party dynamic script set to load without static."
        );
    y &&
      (p && "/" === p[p.length - 1] && (p = p.slice(0, -1)),
      !a.io || (a.io && !a.io.staticMain)
        ? ((g = p + "/" + u + t),
          r
            ? (w.push(g + "static_wdp.js" + k + l),
              w.push(g + "dyn_wdp.js" + k + l),
              b.tp_resource &&
                d(
                  "loader: Invalid Config: both tp_resource and tp_split set. Ignoring tp_resource."
                ))
            : w.push(g + A + k + l))
        : d("loader: Third party script has already been loaded."));
  })();
  q(v);
  q(w);
})();
