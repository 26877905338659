import { observable, action, computed } from 'mobx';
import deviceState from 'stores/DeviceState';

class ViewportState {
  constructor() {
    this.handleResize = _.throttle(() => this.calculateResize(), 20);

    window.addEventListener('resize', ::this.handleResize);
    window.addEventListener(this.supportsOrientationChange, this.handlePrevent);
    this.handlePrevent();
  }

  @observable windowWidth = document.documentElement.clientWidth;
  @observable windowHeight = document.documentElement.clientHeight;

  @observable screenWidth = window.screen.width;
  @observable screenHeight = window.screen.height;

  @observable isLandscape = false;

  fixIframeBody = () => {
    if (
      window.parent &&
      this.isMobile &&
      window.location !== window.parent.location
    ) {
      document.body.style.width = '100vw';
      document.body.style.height = '100vh';
    }
  };

  @computed
  get isMobileLandscape() {
    return (
      this.windowHeight < this.windowWidth && this.md && this.windowHeight < 768
    );
  }

  @computed
  get xssm() {
    return this.windowWidth < 400;
  }

  @computed
  get sm() {
    return this.windowWidth < 544;
  }

  @computed
  get md() {
    return this.windowWidth < 768;
  }

  @computed
  get lg() {
    return this.windowWidth < 991;
  }

  @computed
  get lgxl() {
    return this.windowWidth < 1024;
  }

  @computed
  get lgmdxl() {
    return this.windowWidth < 1250;
  }

  @computed
  get xl() {
    return this.windowWidth < 1366;
  }

  @computed
  get xxl() {
    return this.windowWidth < 2140;
  }

  @computed
  get isMobile() {
    return this.isSmallerThanBreakpoint('sm');
  }

  @computed
  get isTablet() {
    return !this.isSmallerThanBreakpoint('sm') && this.windowWidth <= 1024;
  }

  @computed
  get isIphoneX() {
    // Really basic check for the ios platform
    // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
    const iOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    // Get the device pixel ratio
    const ratio = window.devicePixelRatio || 1;

    // Define the users device screen dimensions
    const screen = {
      width: this.screenWidth * ratio,
      height: this.screenHeight * ratio,
    };

    // iPhone X Detection
    return iOS && screen.width == 1125 && screen.height === 2436;
  }

  @computed
  get isBot() {
    return /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/.test(
      navigator.userAgent,
    );
  }

  @computed
  get isIphone5() {
    return (
      this.isIphone &&
      window.devicePixelRatio >= 2 &&
      screen.availHeight === 568
    );
  }

  @computed
  get isIphone() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('iphone') > -1;
  }

  @computed
  get isIpad() {
    const userAgent = navigator.userAgent;
    return userAgent.indexOf('iPad') > -1;
  }

  @computed
  get isMac() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf('macintosh') > -1;
  }

  @computed
  get isApple() {
    return this.isIphone || this.isMac || this.isIpad;
  }

  @computed
  get showHiddenBetslip() {
    return this.windowWidth < App.Features('betslip.slideBreakpoints');
  }

  get supportsOrientationChange() {
    return 'onorientationchange' in window ? 'orientationchange' : 'resize';
  }

  @computed
  get angleCurrent() {
    try {
      return screen.orientation.angle;
    } catch (e) {
      return window.orientation;
    }
  }

  handlePrevent = () => {
    this.isLandscape = this.angleCurrent !== 0;
  };

  @computed
  get isWebView() {
    return !(
      this.isMobile ||
      this.isTablet ||
      deviceState.isAndroid ||
      deviceState.isIphone
    );
  }

  @action
  calculateResize = () => {
    this.windowWidth = document.documentElement.clientWidth;
    this.windowHeight = document.documentElement.clientHeight;

    this.screenWidth = window.screen.width;
    this.screenHeight = window.screen.height;
  };

  isSmallerThanBreakpoint = breakpoint => this[breakpoint];

  iosVersion = () => {
    var match = navigator.userAgent.match(/OS (\d+)_(\d+)_?(\d+)?/),
      version;

    if (match !== undefined && match !== null) {
      version = [
        parseInt(match[1], 10),
        parseInt(match[2], 10),
        parseInt(match[3] || 0, 10),
      ];
      return parseFloat(version.join('.'));
    }
    return false;
  };

  androidVersion = () => {
    const ua = navigator.userAgent.toLowerCase();
    var match = ua.match(/android\s([0-9\.]*)/i);
    return match ? parseFloat(match[1]) : false;
  };
}

const store = new ViewportState();
export default store;
