export let blackbox = null;

const bb_callback = (bb, complete) => {
  if (complete) {
    blackbox = bb;
  }
};

export const load = () => {
  /* Copyright(c) 2016, iovation, inc. All rights reserved. */
  window.io_global_object_name = 'IGLOO';
  window.IGLOO = window.IGLOO || {
    install_flash: false,
    bbout_element_id: 'ioBB',
    bb_callback: bb_callback,
    loader: {
      version: '5.0.0',
    },
  };
  require('thirdparty/iovation/loader');
};
