import { colors } from 'app/theme/colors';
import color from 'color';

export default {
  BankDetailsView: {
    card: {
      border: colors.inverseBorder,
      header: colors.primaryBg,
    },
    error: {
      text: colors.error,
    },
  },
};
