import { colors } from 'app/theme/colors';

export default {
  DocumentUpload: {
    tooltipText: {
      text: colors['grey-90'],
    },
    selector: {
      text: '#969696',
    },
  },
};
