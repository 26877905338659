export const CURRENCY_SYMBOL_DICT = {
  GBP: '£',
  EUR: '€',
  BRL: 'R$',
  SEK: 'kr',
  USD: '$',
  MXN: '$',
  MYR: 'RM',
  IRD: 'Rp',
  JPY: '¥',
  NOK: 'kr',
  IRR: '﷼',
  TRY: '₺',
  INR: '₹',
  MBC: 'mɃ',
  CHIPS: '©',
  ZAR: 'R',
  RMB: '¥',
};

// export const DEBUG = (process.env.NODE_ENV !== 'production')
export const BOOT_COMPLETE = 'App:BootComplete';
export const LOGOUT = 'logout';
export const LOGEDIN = 'logedin';
export const OPEN_LOGIN_MODAL = 'loginModal:open';
export const CLOSE_LOGIN_MODAL = 'loginModal:close';
export const CLOSE_SIDE_BAR = 'sidebar:close';
export const OPEN_SIDE_BAR = 'sidebar:open';
export const TOGGLE_BET_SLIP = 'betslip:toggle';
export const CLOSE_BET_SLIP = 'betslip:close';
export const OPEN_BET_SLIP_HINT = 'betslipHint:open';
export const TOGGLE_CONTACT = 'contact:toggle';
export const OPEN_CONTACT_CHAT = 'contact:chat';
export const OPEN_CONTACT_FAQ = 'contact:faq';
export const OPEN_CONTACT_DETAILS = 'contact:details';
export const BET_HISTORY_NAVIGATE = 'betHistory:navigate';
export const CASINO_SEARCH = 'casino:search';

export const SCROLL_TRIGGER = 'mainContentScroll:trigger';

export const APP_NAVIGATE = 'App:navigate';
export const TRIGGER_USER_ACTIVITY = 'inactivityTimer:userAction';
export const ROUTE_NOT_FOUND = 'route-not-found';

export const BETSLIP_LOADED = 'betslip:loaded';
export const BETSLIP_LOADING = 'betslip:loading';
export const betslipMinstake = () =>
  App.currencyFeatures(
    'betslip.minStake',
    App.session.request('currency'),
    0.1,
  );

export const TRIGGER_RESIZE = 'resize';
export const PROMO_IMAGE_LOADED = 'promoImage:loaded';

export const BURGER_COLLAPSE_ALL = 'BurgerCollapseAll';

export const MAX_NUM_AUTOMATIC_ROWS_FOR_WIDGETS = 7;
export const MAX_TILESPAN_FOR_WIDGETS = 3;
export const ABSOLUTE_MAX_NUM_ROWS_FOR_WIDGETS = 80;
export const MAX_NUM_GROUPS_FOR_LEAGUE_SCHEDULE = 3;

export const MULTI_VIEW_MARKETS = 2;

export const DEFAULT_ODDS_INDICATOR_SIGN = '@';

export const PRODUCTS = Object.freeze({
  SPORTSBOOK: 'sportsbook',
  LIVE_BETTING: 'live_betting',
  CASINO: 'casino',
  LIVE_CASINO: 'live_casino',
  PROMOTIONS: 'promotions',
});

export const DEVICE_TYPE = Object.freeze({
  ANDROID: 'ANDROID',
  IOS: 'iOS',
});

export const ROUTE_MODAL_TYPE = Object.freeze({
  accountModal: 'account',
  registerModal: 'register',
  loginModal: 'login',
});

export const ROUTES_MODALS = Object.freeze({
  modals: [
    {
      type: ROUTE_MODAL_TYPE.accountModal,
      routes: [
        'withdraw',
        'mybets',
        'deposit',
        'bonuses',
        'transactions',
        'archive',
        'account',
        'payout',
      ],
      featureCheck: 'account.modal',
    },
    {
      type: ROUTE_MODAL_TYPE.registerModal,
      routes: ['register'],
      featureCheck: 'registration.modal',
    },
    {
      type: ROUTE_MODAL_TYPE.loginModal,
      routes: ['login'],
      featureCheck: 'login.modal',
    },
  ],
});

export const SUBSCRIPTION_TYPES = Object.freeze({
  full: 'full',
  keyMarket: 'keyMarket',
});

export const MARKET_GROUPS = Object.freeze({
  SessionMarkets: 'SESSION MARKETS',
  Meter: 'METER',
  TDScorerPlayerProps: 'TDSCPP',
  OffensePlayerProps: 'OFFENSEPP',
  DefensePlayerProps: 'DEFENSEPP',
  PassingPlayerProps: 'PASSINGPP',
  ReceivingPlayerProps: 'RECEIVINGPP',
  RushingPlayerProps: 'RUSHINGPP',
  KickingPlayerProps: 'KICKINGPP',
  GeneralPlayerProps: 'GENERALPP',
  BattingPlayerProps: 'BATTINGPP',
  PitchingPlayerProps: 'PITSHINGPP',
  SkatingPlayerProps: 'SKATINGPP',
  GoaltendingPlayerProps: 'GOALTENDINGPP',
});

export const REGISTRATION_STATUSES = Object.freeze({
  unknown: 'UNKNOWN',
  new: 'NEW',
  unverified: 'UNVERIFIED',
  emailSent: 'EMAIL_SENT',
  emailConfirmed: 'EMAIL_CONFIRMED',
  documentsUploaded: 'DOCUMENTS_UPLOADED',
  documentsRejected: 'DOCUMENTS_REJECTED',
  documentsApproved: 'DOCUMENTS_APPROVED',
  completed: 'COMPLETED',
  pendingVerification: 'PENDING_DOCV_RESULT',
});

export const REGISTRATION_STATUS_MAP = Object.freeze({
  [REGISTRATION_STATUSES.unverified]: -100,
  [REGISTRATION_STATUSES.new]: 0,
  [REGISTRATION_STATUSES.unverified]: 50,
  [REGISTRATION_STATUSES.emailSent]: 100,
  [REGISTRATION_STATUSES.emailConfirmed]: 200,
  [REGISTRATION_STATUSES.documentsUploaded]: 300,
  [REGISTRATION_STATUSES.documentsRejected]: 350,
  [REGISTRATION_STATUSES.documentsApproved]: 400,
  [REGISTRATION_STATUSES.completed]: 500,
});

export const REQUIRED_FIELDS_VERIFICATION = Object.freeze({
  fields: [
    'ssnLast4Digits',
    'postcode',
    'city',
    'dob',
    'line2',
    'phone1',
    'state',
  ],
});

export const REQUIRED_FIELDS_VERIFICATION_V2 = Object.freeze({
  fields: [
    'firstName',
    'surname',
    'dob',
    'state',
    'postcode',
    'countryCode2',
    'ssnLast4Digits',
    'email1',
    'city',
    'line1',
    'line2',
    'phone1',
  ],
});
