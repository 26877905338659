export const ROUTES_TO_TRANSLATE = [];

/*
export const ROUTES_TO_TRANSLATE = [
  "route.home",
  "route.games",
  "route.games.rules",
  "route.lobby",
  "route.login",
  "route.withdraw",
  "route.withdraw.amount",
  "route.withdraw.confirm",
  "route.withdraw.error",
  "route.withdraw.method",
  "route.withdraw.newaccount",
  "route.withdraw.restrict",
  "route.withdraw.verificationerror",
  "route.deposit",
  "route.deposit.amount",
  "route.deposit.spei",
  "route.deposit.confirm",
  "route.deposit.error",
  "route.deposit.restrict",
  "route.not-found",
  "route.account",
  "route.account.overview",
  "route.account.details",
  "route.account.documents",
  "route.account.exclusions",
  "route.account.communication",
  "route.account.password",
  "route.bonuses",
  "route.bonuses.sports",
  "route.bonuses.casino",
  "route.passwordnew",
  "route.mybets",
  "route.mybets.all",
  "route.mybets.open",
  "route.mybets.win",
  "route.mybets.lose",
  "route.mybets.closed",
  "route.mybets.cancelled",
  "route.passwordforgot",
  "route.register",
  "route.register.profile",
  "route.register.personal",
  "route.register.confirm",
  "route.register.success",
  "route.register.failed",
  "route.transactions",
  "route.transactions.sports",
  "route.transactions.games",
  "route.transactions.casino",
  "route.chips",
  "route.chips.select",
  "route.chips.buy",
  "route.chips.buysuccess",
  "route.chips.sell",
  "route.chips.sellsuccess",
  "route.chips.sellerror"
];
*/
