import { colors } from 'app/theme/colors';

export default {
  loginView: {
    wrapper: {
      padding: '2.85714rem 0',
    },
    button: {
      bg: colors.primary,
      text: colors.white,
    },
  },
};
