import { observable, action } from 'mobx';

class Messages {
  @observable isSendingMessage = false;

  @action
  setSendingMessageStatus = val => {
    this.isSendingMessage = val;
  };
}

const store = new Messages();
export default store;
