/**
 * @param str
 * @param val
 * @returns {boolean}
 */
export var isNotLoggedIn = function(resp) {
  if (_.has(resp, 'Response')) {
    var response = resp.Response;
    if (_.has(response.status, 'Error')) {
      var error = response.body.Error;
      return error.code == 'NOT_LOGGED_IN';
    }
  }
  if (_.has(resp, 'Error')) {
    var error = resp.Error;
    return error.code == 'NOT_LOGGED_IN';
  }
  return false;
};

export const isLoginLimitError = resp => {
  const response = resp?.Response;
  return (
    (response?.status?.Error &&
      response?.body?.Error?.code === 'LOGIN_LIMIT_ERROR') ||
    resp?.Error?.code === 'LOGIN_LIMIT_ERROR'
  );
};

/**
 * @param resp
 * @returns {*}
 */
export var hasError = function(resp) {
  if (_.has(resp, 'Response')) {
    var response = resp.Response;
    return response.status == 'ERROR';
  }
  return _.has(resp, 'Error');
};
