import sendNativeMessage from './SendNativeMessage';
import login from 'common/command/Login';
import routing from 'app/stores/routing';
import menuState from 'stores/MenuState';
import globals from 'app/stores/globals';

/* eslint-disable  no-restricted-globals */
/* eslint-disable  prefer-rest-params */

class NativeHelpers {
  init() {
    App.NativeHelpers = {};
    App.NativeHelpers.login = this.userLogin;
    App.NativeHelpers.getCookie = this.getCookie;
    App.NativeHelpers.setUpUrlListeners = this.setUpUrlListeners;
    App.NativeHelpers.goBack = this.goBack;
    App.NativeHelpers.forceShowDepositRestriction = this.forceShowDepositRestriction;
    App.NativeHelpers.onLocationAcquired = this.onLocationAcquired;
  }

  goBack = () => {
    routing.goBack();
  };

  forceShowDepositRestriction = () => {
    menuState.forceShowDepositRestriction();
  };

  onLocationAcquired = (latitude, longitude) => {
    if (latitude && longitude) {
      globals.longitude = longitude;
      globals.latitude = latitude;
    } else {
      globals.setLocation();
    }
  };

  setUpUrlListeners = () => {
    const { history } = window;
    const { pushState } = history;
    history.pushState = function() {
      setTimeout(() => {
        window.postMessage(
          JSON.stringify({
            type: 'navStateChange',
            navState: { url: location.href, title: document.title },
          }),
          '*',
        );
      }, 100);
      return pushState.apply(history, arguments);
    };
  };

  userLogin = (username, password) => {
    login(
      encodeURI(username),
      password,
      App.Config.siteId,
      App.Globals.brandCode,
    )
      .then(resp => {
        if (resp && resp.Login) {
          sendNativeMessage(`VALID_CREDENTIALS:::${resp.Login.accountId}`);
        }
      })

      .catch(error => {
        if (error.Error && error.Error.code) {
          sendNativeMessage(error.Error.code);
        }
      });
  };

  getCookie = () => {
    const cookies = document.cookie.split(';');
    const token = cookies.find(cookie => cookie.includes('ats_token'));
    if (token) {
      sendNativeMessage(`COOKIE:::${token.trim()}`);
    }
  };
}

export default new NativeHelpers();
