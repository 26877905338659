var HttpResponse = require('common/service/HttpResponse.js');

module.exports = Backbone.Model.extend({
  methodMap: {
    POST: 'create',
    PUT: 'update',
    DELETE: 'delete',
    POSTJSON: 'create',
    POSTHTML: 'create',
    GETJSON: 'read',
    GET: 'read',
  },

  headerMap: {
    GETJSON: { Accept: 'text/x-json' },
    POSTJSON: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    POSTHTML: {
      Accept: 'text/html',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  },

  /**
   *
   * Targets object should be an object of service method object definitions.
   *
   * Each definition, should have the actual remote service method name as it's key,
   * and an options objects as follows:
   *
   * The options can comprise of all/any of the following two option definitions:
   *
   * 1) 'method' - the service method type.  Can be any of the values above from the methodMap object.  If a 'method' hash is not provided, 'get' is used as default. Case-unsensitive. ie:
   *
   * targets: {
   *  'someServiceMethod': {
   *      'method': 'post'
   *  }
   * }
   *
   * 2) 'args' - arguments for the method call.  Should be an array of argument definitions.  An argument definition should either be:
   *  a) A String, specifying the remote argument name, and denoting a required argument that should always be provided
   *  b) An object containing the argument name as key, and the argument's default value, if not explicitly provided by user.
   *  c) An object containing the argument name as key, and another object, with key 'attr' and name of an attribute on the model to retrieve the value
   *
   * defaults: {
   *  sessionToken: function(){
   *      return this.session.getSessionToken();
   *  },
   * }
   *
   * targets: {
   *  'someServiceMethod': {
   *      'method': 'post',
   *      args: [
   *          'firstRequiredArgument',                    // required argument must be applied to function invocation (see a)
   *          {'secondArgument': 'defaultValue'}          // non-required argument, has default value specified (see b)
   *          {'thirdArgument': { attr: 'sessionToken'}}  // non-required argument, use implicit value (see c)
   *      ]
   *  }
   * }
   *
   *
   * The arguments array, should match exactly the order or arguments supplied to the method, when providing user defined values.
   * Any number of unrequired methods may be stipulated as null or undefined or '' to prevent the default value being overriden.
   */

  /**
   * @param options
   */
  initialize(options) {
    this.targetsObjs = this.parseTargets(this.targets || {});
    _.bindAll(this, 'createMethod');
    _.each(this.targetsObjs, this.createMethod, this);
  },

  /**
   * Crete a target object for each provided target method
   */
  parseTargets(targets) {
    return _.map(targets, function(options, request) {
      var target = { request, method: 'get', args: [], secure: false };

      // if is string, the options should only be a method type such as 'POST'
      if (_.isString(options) && !_.isUndefined(options))
        target.method = options.toUpperCase();

      // if is an array, assumed to be an array of required arguments names
      if (_.isArray(options)) target.args = options;

      // if is a json object, just extend the target object with new values
      if (_.isObject(options)) _.extend(target, options);

      target.method = target.method.toUpperCase();
      return target;
    });
  },

  /**
   * Create the actual targets' method on this service.
   *
   * Calling the method returns a JQuery Promise object.  This provides a subset
   * of the callback methods of the Deferred object (then, done, fail, always,
   * pipe, and state).  This enables multiple ways of responding to a promises
   * response.
   *
   * For example:
   *
   *  var promise = this.api.login('jamie', 'password');
   *
   *  promise.then(function(resp){
   *      // do something with the success response
   *  });
   *
   *  promise.catch(function(resp){
   *      // do something with the failure response
   *  });
   *
   *  promise.always(function(resp){
   *      // always do something regardless of outcome
   *  });
   *
   * promise.progress(function(prog){
   *      // Get Progress updates if applicable - uploads - if supported by browser
   * };
   *
   * You can also use the 'ten' shorthand for adding the previous callbacks in one go:
   *
   *  promise.then(doneCallback, failCallback, alwaysCallback);
   *
   * @param target
   */
  createMethod(target) {
    var scope = this;
    this[target.request] = function(opts) {
      var args = Array.prototype.slice.call(arguments);
      return new Promise(function(resolve, reject) {
        var options = scope.createOptions(target, args, resolve, reject, scope),
          method = scope.methodMap[target.method];

        if (opts) _.extend(options, opts);
        // if (target.method.toLowerCase() == 'post') {
        //	options.contentType = 'application/x-www-form-urlencoded';
        // }

        Backbone.sync(method, scope, options);
      });
    };
  },

  /**
   * @param target
   * @param data
   * @param deferred
   * @returns {{url: string, data: *, success: success, error: error}}
   */
  createOptions(target, data, resolve, reject, scope) {
    var secure = !!target.secure;
    var url =
        target.url || (secure && !_.isEmpty(this.surl()))
          ? this.surl()
          : this.url(),
      payload = data[0];
    if (!!target.rest) {
      if (typeof target.edit !== 'undefined') {
        target.request = data[0];
        payload = data[1];
      } else {
        target.request = data[1] + '/numbers';
      }
    }
    var rest = target.proxy || target.rest || target.request;
    var options = this.addHeaders(
      {
        url: url.replace(/\/$/, '') + '/' + rest,
        data: this.getParams(target, data),
        success(data, status, xhr) {
          // reject if not logged in
          if (HttpResponse.isNotLoggedIn(data)) {
            App.bus.trigger('session:errorNotLoggedIn', data);
            reject(data);
            return;
          }
          if (HttpResponse.isLoginLimitError(data)) {
            App.bus.trigger('session:errorLoginLimit', data);
            reject(data);
            return;
          }
          // reject if any other error
          if (HttpResponse.hasError(data)) {
            reject(data);
            return;
          }
          resolve(data);
        },
        error(xhr, status, err) {
          reject(err);
        },
      },
      target.method,
      target.headers,
      scope,
    );

    if (!!data.jsonp) {
      options.dataType = 'jsonp';
    }

    if (target.method == 'GETJSON' || target.method == 'POSTJSON')
      _.extend(options, { dataType: 'json' });

    if (target.method == 'POSTHTML') _.extend(options, { dataType: 'text' });

    if (target.method == 'POSTJSON') {
      _.omit(options, 'data');
      _.extend(options, { data: JSON.stringify(data[0]) });
    }
    if (!!target.rest) {
      _.omit(options, 'data');
      _.extend(options, { data: JSON.stringify(payload) });
    }
    return options;
  },

  /**
   * Returns a resolved params object, with all required parameters added
   * @param args
   * @param data
   */
  getParams(target, data) {
    var params = {},
      that = this;

    // iterate through each argument
    _.each(target.args, function(arg, index) {
      // if it's a string, it's an expected arg, so take it from the data array
      if (_.isString(arg)) {
        params[arg] = data[index];
      }

      // if it's an object, it's either been set with a default
      // value, is optional if no default is specified, or an implicit
      // value.  Should set the default/implicit, before applying user value
      else if (_.isObject(arg)) {
        const pairs = _.pairs || _.toPairs; // underscore/lodash
        var keyValue = pairs(arg)[0];

        // implicit - as denoted by the 'attr' property

        // the argument value is an 'attr' type object, which denotes an implicit value.
        if (_.isObject(keyValue[1]) && _.has(keyValue[1], 'attr')) {
          // get and set the implicit value
          params[keyValue[0]] = that.get(keyValue[1].attr);
        } else {
          // default - add default before overwriting with user value
          if (!_.isEmpty(keyValue[1])) {
            params[keyValue[0]] = keyValue[1];
          }

          // user - overwrite with user value
          if (data.length >= index) {
            var val = data[index];

            if (_.isString(val) && !!val.length) {
              params[keyValue[0]] = val;
            } else if (_.isNumber(val) && !_.isNaN(val)) {
              params[keyValue[0]] = val;
            } else if (_.isBoolean(val)) {
              params[keyValue[0]] = val;
            }
          }
        }
      }
    });

    return $.param(params);
    // return params;
  },

  /**
   * Extends the target options to include any default headers for the request method type
   * @param options
   * @param method
   * @returns {*}
   */
  addHeaders(options, method, hdrs, that) {
    var headers = this.headerMap[method];
    if (headers) _.extend(options, { headers });

    // add custom headers
    if (hdrs) {
      if (!_.has(options, 'headers')) options.headers = {};

      if (_.isObject(hdrs) && _.has(hdrs, 'attr')) {
        hdrs = that.get(hdrs.attr);
      }
      _.extend(options.headers, hdrs);
    }

    if (App.session.request('loggedIn')) {
      if (!_.has(options, 'headers')) options.headers = {};
      const csrf_token = App.session.request('csrfToken');
      _.extend(options.headers, { csrf_token });
    }

    return options;
  },

  /**
   * Extends the backbone 'get' method to return invoked function calls for model attributes:
   *
   * defaults: {
   *  robotSays: function(){
   *      return "Beep Beep"
   *  }
   * }
   *
   * this.get('robotSays') >  'Beep Beep'
   *
   * @param attr
   * @returns {*}
   */
  get(attr) {
    var value = Backbone.Model.prototype.get.call(this, attr);
    return _.isFunction(value) ? value.call(this) : value;
  },
});
