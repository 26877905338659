import { observable, computed, action, get } from 'mobx';

/*
 * This is to be used when you have, multiple menu sections inside of a menu
 * it expects those sections to be private on the parent ie _betslip = new Menu()
 * it should recive the parent and the key of the private menu;
 * closeAll defaults to true and will attempt to close all other sections when opening its own
 *    using the method 'closeAll' on the parent
 *
 */

class SectionController {
  @observable
  _routingParams = {};

  @observable
  additionalData = {};

  constructor(parent, key, closeAll = true) {
    this.parent = parent;
    this.key = key;
    this.closeAll = closeAll;
  }

  @computed
  get isOpen() {
    return this.parent[this.key].isOpen;
  }

  @computed
  get routingParams() {
    return this._routingParams;
  }

  @action
  getRoutingParamByKey(key) {
    return get(this._routingParams, key) ?? undefined;
  }

  @action
  setRoutingParam(params) {
    this._routingParams = params;
  }

  open = (additionalData = {}) => {
    if (this.closeAll && this.parent.closeAll) {
      this.parent.closeAll();
    }
    this.parent[this.key].open();
    this.additionalData = additionalData;
  };

  toggle = () => {
    if (this.isOpen) {
      this.close();
    } else {
      this.open();
    }
  };

  close = () => {
    this._routingParams = {};
    this.parent[this.key].close();
    this.additionalData = {};
  };
}

export default SectionController;
