import deepMerge from 'deepmerge';
const dontMerge = (destination, source) => source;
const setVariables = (skinVariables = {}, skinVariablesMobile = {}) => {
  const defaultVariables = {
    MarketsHeaderMobilePadding: '10px 10px 10px 10px',
    selectionViewBlockMaxHeight: 27,
    europeanMarketsViewContainerPadding: 10,
    europeanMarketsViewFixturesPadding: 0,
    priceBlockFontWeight: 'normal',
    priorityNavIconMobileWidth: 30,
    priorityNavIconMobileHeight: 'auto',
    priorityNavIconWidth: 50,
    priorityNavIconHeight: 50,
    priotiyNavPadding: '12px 25px',
    sportIconsOpacity: '0.7',
    priorityNavBarPadding: 0,
    prioritySportsMenuPaddingLeft: 0,
    loginInputWidth: '100%',

    selectionViewBlockPadding: 0,
    selectionViewBlockRadius: 2,
    appViewContetntMargin: 5,
    appViewContetntMarginDesk: 20,

    eventPageMenuBorder: undefined,
    InfoBox: {
      padding: '0 5px',
      liveClock: {
        justifyContent: undefined,
      },
    },
    infoBoxContainerJustifyContent: 'center',
    infoBoxContainerFontSize: 12,
    infoBoxScorePadding: undefined,
    selectionViewBlockWidth: '98%',
    cardAsianFixedHeightCell: 40,
    priceBlockPriceLineHeight: '1',
    selectionViewLineFontSize: 10,
    burgerMenuViewHolderMarginLeft: undefined,
    burgerMenuViewHolderMarginTop: undefined,
    widgetBaseTitleFontWeight: 400,
    bigLogoWidth: '140px',
    headerContainerPadding: '0px 30px',
    headerViewWrapperBorderBottom: undefined,
    buttonBorder: '0',
    buttonBorderRadius: '0',
    responsibleGamingContainerPadding: '5px 24px',
    priorityNavItemIconV2Padding: '12px',
    marketNumberBottomDisplayPaddingBottom: undefined,
    marketNumberTextWeight: undefined,
    participantsAsianMargin: 10,
    participantsFSMobile: 12,
    eventTimeFontSize: 'inherit',
    infoBoxContainerWidth: 48,
    buttonButtonPadding: 12,
    PlaceBetButtonFontSize: '13px !important',
    placeBetContainerMarginTop: 17,
    globalsBodyFontSize: '90%',
    numericalInputInputBorder: /*`1px solid ${theme.colors.betslipInputBorderColor} !important`*/ `1px solid !important`,
    numericalInputInputHeight: '30px !important',
    numericalInputInputTextAlign: 'right',
    numericalInputInputPadding: '2px 5px 0 !important',
    numericalInputOddsFontWeight: 'bold',
    participantsInfoFontSize: '14px',
    burgerSelectionsContainerPaddingBottom: 'unset',
    marketViewMarginBottom: 10,
    keyboardButtonHeight: 40,
    filterContainerPadding: '3px 0px',
    mainBoddyPaddingMobile: 0,
    marketViewHeaderMarginBottom: undefined,
    marketViewHeaderText: 'uppercase',
    widgetBaseHeaderPadding: 10,
    selectionViewBlockBorderLeft: undefined,
    europeanMarketViewInfoBoxContainerWidth: '100%',
    burgerSelectionsContainerPaddingRight: 4,
    highlightContainerMargin: '0px',
    mainViewContainerPadding: '12px 0px',
    europeanMarketViewInfoBoxContainerTextAlign: 'center',
    infoBoxUpcomningTimeJustify: 'center',
    scoreMarginTop: -2,
    infoContainerAsianHeight: '65px',
    infoContainerAsianMargin: '18px 0',
    highlightedScorePadding: 0,
    minWidthHeaderTime: '115px',

    tAndCModalTitleFontWeight: undefined,
    tAndCModalContentFontWeight: undefined,
    tAndCModalContainerWidth: undefined,
    tAndCModalContainerMargin: undefined,
    tAndCModalButtonWidth: undefined,
    tAndCModalButtonHeight: undefined,

    casinoHeaderContainerMargin: '10px 0px',

    cardAsianLabelWideFontSize: 14,
    itemPriorityNavitemFontSize: 12,
    priceBlockPriceFontSize: '13px',
    marketHeaderHeaderFontSize: '12px',
    marketsHeaderTypeSelectionFontSize: '11px !important',
    infoBoxUpcomningTimeFontSize: '10px !important',
    widgetBaseSubTitleFontSize: undefined,

    betSlipSummaryLabelOapcity: '0.5',

    mainViewMinHeight: '100vh',
    selectionViewEventPageHeight: 29,

    navBarNavOptionHeight: 42,
    collapsibleHeaderPadding: 10,
    collapsibleSecondaryHeight: undefined,

    headerViewContainerStyledHeight: 70,
    headerViewWrapperStylePaddingTop: 10,

    smallHeaderDropdownContainerMarginTop: undefined,

    betslipRejectionLinkBg: undefined,
    betslipRejectionLinkPadding: undefined,
    betslipRejectionLinkBorderRadius: undefined,

    headerViewContainerThinPadding: '7px 30px 7px 20px',
    betSlipBetslipContainerMarginTop: 90,

    europeanMarketsViewScoreContainerHeight: undefined,
    europeanMarketsViewScoreContainerAlignContent: undefined,
    europeanMarketsViewScoreContainerMarginTop: -2,
    europeanMarketsViewScoreContainerMarginBottom: undefined,

    outhrightViewTitleTextFontWeight: undefined,

    selectionViewSelectionViewInOutrightsPadding: '0px 12px',
    priceBlockContainerHorizontalDirection: 'row',
    mainViewContainerPaddingCustom: '12px',

    StatusMessageStatusErrorMarginTop: 30,
    StatusMessageStatusSuccessMarginTop: 30,

    headerBgName: 'header_bg',
    productNavInHeaderHeight: 0,

    selectionViewSelectionViewInOutrightsMaxHeight: '36px',
    outrightsLeagueViewHeaderPadding: 5,
    widgetBaseHeaderBorder: undefined,

    filterTableContentPadding: '10px 5px 20px',

    backSelectionBg: undefined,
    laySelectionBg: undefined,
    backLayMarketViewColumnPadding: 3,
    backlayv2BorderRadius: '2px',
    depositAmountViewButtonContainerGrid: '1fr 1fr 1fr 1fr 1fr',

    priceBlockSelectedWeight: undefined,

    userDropDownViewRegisterTextSize: '10px',

    footerViewType1ContainerFontSize: undefined,
    singleBetGroupMarginBottom: 17,
    singleGroubedBetHeaderPadding: '5px 10px 20px',

    headerViewWrapperShadow: undefined,
    cardCardBgOpacity: 0.5,

    raceCardSectionItemMarginLeft: 0,
    sportsPromotionSelectionWidth: '50%',
    sportsPromotionEventNameSize: '25px',

    accountAreaAccountAreaNotDefaultWidth: 300,
    logoLogoConatinerNotDefaultWidth: '230px',

    /// app/view/components/SearchField/SearchField.jss
    SearchField: {
      height: 36,
    },
    SelectionView: {
      margin: 'auto',
    },
    messagesTextareaHeight: '356px',
    messagesErrorDistanceFromTop: '306px',

    marketViewBorderRadius: undefined,
  };

  return deepMerge.all([defaultVariables, skinVariables, skinVariablesMobile], {
    arrayMerge: dontMerge,
  });
};

export default setVariables;
